export const defaultResidentList = [
  {
    PropertyID: 166313,
    PropertyName: 'Camelback Square',
    UnitNumber: '1069',
    Address: '1714 21st St',
    Secondary: 'Apt 1069',
    City: 'Sacramento',
    State: 'CA',
    Zip: '95811',
    ResidentID: 799,
    UnitID: 13738,
    TenantId: 'TIN5982337',
    ResidentStatusID: 1,
    ResidentStatusName: 'Future',
    ResidentRelationshipID: 1,
    ResidentRelationshipName: 'Head of Household',
    FirstName: 'Jordan',
    LastName: 'Almog',
    Email: 'conference+32221@movingin.com',
    Phone: '602-791-8802',
    DateLeaseStart: '2023-01-17T00:00:00',
    DateLeaseRenewal: '2024-04-17T00:00:00',
    Created: '2022-10-20T22:52:15.287',
    CreatedBy: 'kurt@movingin.com',
    Updated: null,
    UpdatedBy: null,
    InvitationID: 58,
    InvitationLink:
      'https://www.movingin.com/dashboard?invite=QkRDNDVCNEUtRUQ2OC00RjUxLUE5N0EtOTg1QjVCQjFBNUMz',
    HasInvitation: true,
    DateInvitationCreated: '2023-01-17T20:58:50.767',
    DateInvitationDelivered: '2023-01-17T17:00:18.14',
    DateInvitationRead: '2023-01-17T17:03:06.313',
    DateInvitationClicked: '2023-01-13T17:03:46',
    InvitationUrlClicked:
      'https://www.movingin.com/dashboard?invite=QkRDNDVCNEUtRUQ2OC00RjUxLUE5N0EtOTg1QjVCQjFBNUMz',
    DateInvitationDropped: null,
    InvitationDropReason: null,
    DateInvitationVisited: '2022-11-29T15:41:59.237',
    ServiceAddressID: 8390519,
    DisplayOrder: 1,
    Grouping: 'provider-service',
    Description: 'Electric Service',
    ResidentOnboardingStatusID: 3,
    Reason: '',
    ProviderServiceID: 9,
    ResidentVehicleID: null,
    ResidentPetID: null,
    OnboardingColumn: 'Electric',
    ResidentOnboardingStatusName: 'Completed',
    ServiceProfileID: 56,
    CountBedroom: null,
    CountBathroom: null,
    SquareFootage: null,
    DaysToLeaseStart: 0,
    Previous_Address: null,
    Previous_Secondary: null,
    Previous_City: null,
    Previous_State: null,
    Previous_Zip: null,
    IsResidentOnboarding: 1,
    DateLastUpdated: '2023-01-17T21:41:26.16',
    ExportFileName: 'TIN5982337_ElectricPOS.pdf',
    data: {
      Electric: {
        ResidentOnboardingStatusID: 3,
        ResidentOnboardingStatusName: 'Completed',
        Reason: '',
        ProviderServiceList: [
          {
            PropertyID: 166313,
            PropertyName: 'Camelback Square',
            UnitNumber: '1069',
            Address: '1714 21st St',
            Secondary: 'Apt 1069',
            City: 'Sacramento',
            State: 'CA',
            Zip: '95811',
            ResidentID: 799,
            UnitID: 13738,
            TenantId: 'TIN5982337',
            ResidentStatusID: 1,
            ResidentStatusName: 'Future',
            ResidentRelationshipID: 1,
            ResidentRelationshipName: 'Head of Household',
            FirstName: 'Jordan',
            LastName: 'Almog',
            Email: 'conference+32221@movingin.com',
            Phone: '602-791-8802',
            DateLeaseStart: '2023-01-17T00:00:00',
            DateLeaseRenewal: '2024-04-17T00:00:00',
            Created: '2022-10-20T22:52:15.287',
            CreatedBy: 'kurt@movingin.com',
            Updated: null,
            UpdatedBy: null,
            InvitationID: 58,
            InvitationLink:
              'https://www.movingin.com/dashboard?invite=QkRDNDVCNEUtRUQ2OC00RjUxLUE5N0EtOTg1QjVCQjFBNUMz',
            HasInvitation: true,
            DateInvitationCreated: '2023-01-17T20:58:50.767',
            DateInvitationDelivered: '2023-01-17T17:00:18.14',
            DateInvitationRead: '2023-01-17T17:03:06.313',
            DateInvitationClicked: '2023-01-13T17:03:46',
            InvitationUrlClicked:
              'https://www.movingin.com/dashboard?invite=QkRDNDVCNEUtRUQ2OC00RjUxLUE5N0EtOTg1QjVCQjFBNUMz',
            DateInvitationDropped: null,
            InvitationDropReason: null,
            DateInvitationVisited: '2022-11-29T15:41:59.237',
            ServiceAddressID: 8390519,
            DisplayOrder: 1,
            Grouping: 'provider-service',
            Description: 'Electric Service',
            ResidentOnboardingStatusID: 3,
            Reason: '',
            ProviderServiceID: 9,
            ResidentVehicleID: null,
            ResidentPetID: null,
            OnboardingColumn: 'Electric',
            ResidentOnboardingStatusName: 'Completed',
            ServiceProfileID: 56,
            CountBedroom: null,
            CountBathroom: null,
            SquareFootage: null,
            DaysToLeaseStart: 0,
            Previous_Address: null,
            Previous_Secondary: null,
            Previous_City: null,
            Previous_State: null,
            Previous_Zip: null,
            IsResidentOnboarding: 1,
            DateLastUpdated: '2023-01-17T21:41:26.16',
            ExportFileName: 'TIN5982337_ElectricPOS.pdf'
          }
        ],
        DateLastUpdated: '2023-01-17T21:41:26.16',
        ExportFileName: 'TIN5982337_ElectricPOS.pdf'
      },
      'Natural Gas': {
        ResidentOnboardingStatusID: 3,
        ResidentOnboardingStatusName: 'Completed',
        Reason: '',
        ProviderServiceList: [
          {
            PropertyID: 166313,
            PropertyName: 'Camelback Square',
            UnitNumber: '1069',
            Address: '1714 21st St',
            Secondary: 'Apt 1069',
            City: 'Sacramento',
            State: 'CA',
            Zip: '95811',
            ResidentID: 799,
            UnitID: 13738,
            TenantId: 'TIN5982337',
            ResidentStatusID: 1,
            ResidentStatusName: 'Future',
            ResidentRelationshipID: 1,
            ResidentRelationshipName: 'Head of Household',
            FirstName: 'Jordan',
            LastName: 'Almog',
            Email: 'conference+32221@movingin.com',
            Phone: '602-791-8802',
            DateLeaseStart: '2023-01-17T00:00:00',
            DateLeaseRenewal: '2024-04-17T00:00:00',
            Created: '2022-10-20T22:52:15.287',
            CreatedBy: 'kurt@movingin.com',
            Updated: null,
            UpdatedBy: null,
            InvitationID: 58,
            InvitationLink:
              'https://www.movingin.com/dashboard?invite=QkRDNDVCNEUtRUQ2OC00RjUxLUE5N0EtOTg1QjVCQjFBNUMz',
            HasInvitation: true,
            DateInvitationCreated: '2023-01-17T20:58:50.767',
            DateInvitationDelivered: '2023-01-17T17:00:18.14',
            DateInvitationRead: '2023-01-17T17:03:06.313',
            DateInvitationClicked: '2023-01-13T17:03:46',
            InvitationUrlClicked:
              'https://www.movingin.com/dashboard?invite=QkRDNDVCNEUtRUQ2OC00RjUxLUE5N0EtOTg1QjVCQjFBNUMz',
            DateInvitationDropped: null,
            InvitationDropReason: null,
            DateInvitationVisited: '2022-11-29T15:41:59.237',
            ServiceAddressID: 8390519,
            DisplayOrder: 2,
            Grouping: 'provider-service',
            Description: 'Natural Gas Service',
            ResidentOnboardingStatusID: 3,
            Reason: '',
            ProviderServiceID: 8,
            ResidentVehicleID: null,
            ResidentPetID: null,
            OnboardingColumn: 'Natural Gas',
            ResidentOnboardingStatusName: 'Completed',
            ServiceProfileID: 56,
            CountBedroom: null,
            CountBathroom: null,
            SquareFootage: null,
            DaysToLeaseStart: 0,
            Previous_Address: null,
            Previous_Secondary: null,
            Previous_City: null,
            Previous_State: null,
            Previous_Zip: null,
            IsResidentOnboarding: 1,
            DateLastUpdated: '2023-01-17T21:50:26.563',
            ExportFileName: 'TIN5982337_NaturalGasPOS.pdf'
          }
        ],
        DateLastUpdated: '2023-01-17T21:50:26.563',
        ExportFileName: 'TIN5982337_NaturalGasPOS.pdf'
      },
      'Renters Insurance': {
        ResidentOnboardingStatusID: 3,
        ResidentOnboardingStatusName: 'Completed',
        Reason: '',
        ProviderServiceList: [
          {
            PropertyID: 166313,
            PropertyName: 'Camelback Square',
            UnitNumber: '1069',
            Address: '1714 21st St',
            Secondary: 'Apt 1069',
            City: 'Sacramento',
            State: 'CA',
            Zip: '95811',
            ResidentID: 799,
            UnitID: 13738,
            TenantId: 'TIN5982337',
            ResidentStatusID: 1,
            ResidentStatusName: 'Future',
            ResidentRelationshipID: 1,
            ResidentRelationshipName: 'Head of Household',
            FirstName: 'Jordan',
            LastName: 'Almog',
            Email: 'conference+32221@movingin.com',
            Phone: '602-791-8802',
            DateLeaseStart: '2023-01-17T00:00:00',
            DateLeaseRenewal: '2024-04-17T00:00:00',
            Created: '2022-10-20T22:52:15.287',
            CreatedBy: 'kurt@movingin.com',
            Updated: null,
            UpdatedBy: null,
            InvitationID: 58,
            InvitationLink:
              'https://www.movingin.com/dashboard?invite=QkRDNDVCNEUtRUQ2OC00RjUxLUE5N0EtOTg1QjVCQjFBNUMz',
            HasInvitation: true,
            DateInvitationCreated: '2023-01-17T20:58:50.767',
            DateInvitationDelivered: '2023-01-17T17:00:18.14',
            DateInvitationRead: '2023-01-17T17:03:06.313',
            DateInvitationClicked: '2023-01-13T17:03:46',
            InvitationUrlClicked:
              'https://www.movingin.com/dashboard?invite=QkRDNDVCNEUtRUQ2OC00RjUxLUE5N0EtOTg1QjVCQjFBNUMz',
            DateInvitationDropped: null,
            InvitationDropReason: null,
            DateInvitationVisited: '2022-11-29T15:41:59.237',
            ServiceAddressID: 8390519,
            DisplayOrder: 3,
            Grouping: 'provider-service',
            Description: 'Renters Insurance',
            ResidentOnboardingStatusID: 3,
            Reason: '',
            ProviderServiceID: 37,
            ResidentVehicleID: null,
            ResidentPetID: null,
            OnboardingColumn: 'Renters Insurance',
            ResidentOnboardingStatusName: 'Completed',
            ServiceProfileID: 56,
            CountBedroom: null,
            CountBathroom: null,
            SquareFootage: null,
            DaysToLeaseStart: 0,
            Previous_Address: null,
            Previous_Secondary: null,
            Previous_City: null,
            Previous_State: null,
            Previous_Zip: null,
            IsResidentOnboarding: 1,
            DateLastUpdated: '2023-01-17T21:51:13.54',
            ExportFileName: 'TIN5982337_RentersInsurancePOS.pdf'
          }
        ],
        DateLastUpdated: '2023-01-17T21:51:13.54',
        ExportFileName: 'TIN5982337_RentersInsurancePOS.pdf'
      },
      'Vehicle Registration': {
        ResidentOnboardingStatusID: 3,
        ResidentOnboardingStatusName: 'Completed',
        Reason: '',
        ProviderServiceList: [],
        DateLastUpdated: '2023-01-17T20:41:49.463',
        ExportFileName: 'TIN5982337_VehicleReg.pdf',
        Grouping: 'vehicle'
      },
      'Pet Registration': {
        ResidentOnboardingStatusID: 3,
        ResidentOnboardingStatusName: 'Completed',
        Reason: '',
        ProviderServiceList: [],
        DateLastUpdated: '2023-01-17T18:56:45.92',
        ExportFileName: 'TIN5982337_PetReg.pdf',
        Grouping: 'pet'
      },
      'Move-in Condition Form': {
        ResidentOnboardingStatusID: 0,
        ResidentOnboardingStatusName: 'Sent 01/17/23',
        Reason: '',
        ProviderServiceList: [],
        DateLastUpdated: null,
        ExportFileName: null,
        Grouping: 'walkthrough',
        ExportFileName: 'TIN5982337_MoveInForm.pdf'
      }
    }
  },
  {
    PropertyID: 166313,
    PropertyName: 'Camelback Square',
    UnitNumber: '2018',
    Address: '1714 21st St',
    Secondary: 'Apt 2018',
    City: 'Sacramento',
    State: 'CA',
    Zip: '95811',
    ResidentID: 1006,
    UnitID: 13855,
    TenantId: 'TIN7532204',
    ResidentStatusID: 1,
    ResidentStatusName: 'Future',
    ResidentRelationshipID: 1,
    ResidentRelationshipName: 'Head of Household',
    FirstName: 'Abigail',
    LastName: 'Corral',
    Email: 'conference+42242@movingin.com',
    Phone: '602-791-8802',
    DateLeaseStart: '2023-01-20T00:00:00',
    DateLeaseRenewal: '2024-04-20T00:00:00',
    Created: '2022-10-20T22:52:15.287',
    CreatedBy: 'kurt@movingin.com',
    Updated: null,
    UpdatedBy: null,
    InvitationID: 73,
    InvitationLink:
      'https://www.movingin.com/dashboard?invite=M0YyMTQxM0UtMzFENS00RjkwLUEyNzktNjg3RDkwREVBNjFG',
    HasInvitation: true,
    DateInvitationCreated: '2023-01-20T16:59:42.263',
    DateInvitationDelivered: '2023-01-20T17:00:02.62',
    DateInvitationRead: '2023-01-10T17:03:39.503',
    DateInvitationClicked: '2023-01-15T17:03:39.503',
    InvitationUrlClicked:
      'https://www.movingin.com/dashboard?invite=M0YyMTQxM0UtMzFENS00RjkwLUEyNzktNjg3RDkwREVBNjFG',
    DateInvitationDropped: null,
    InvitationDropReason: null,
    DateInvitationVisited: '2022-11-04T17:03:34.31',
    ServiceAddressID: 8390636,
    DisplayOrder: 1,
    Grouping: 'provider-service',
    Description: 'Electric Service',
    ResidentOnboardingStatusID: 3,
    Reason: '',
    ProviderServiceID: 9,
    ResidentVehicleID: null,
    ResidentPetID: null,
    OnboardingColumn: 'Electric',
    ResidentOnboardingStatusName: 'Completed',
    ServiceProfileID: 56,
    CountBedroom: null,
    CountBathroom: null,
    SquareFootage: null,
    DaysToLeaseStart: 0,
    Previous_Address: null,
    Previous_Secondary: null,
    Previous_City: null,
    Previous_State: null,
    Previous_Zip: null,
    IsResidentOnboarding: 1,
    DateLastUpdated: '2023-01-20T21:52:05.243',
    ExportFileName: 'TIN7532204_ElectricPOS.pdf',
    data: {
      Electric: {
        ResidentOnboardingStatusID: 3,
        ResidentOnboardingStatusName: 'Completed',
        Reason: '',
        ProviderServiceList: [
          {
            PropertyID: 166313,
            PropertyName: 'Camelback Square',
            UnitNumber: '2018',
            Address: '1714 21st St',
            Secondary: 'Apt 2018',
            City: 'Sacramento',
            State: 'CA',
            Zip: '95811',
            ResidentID: 1006,
            UnitID: 13855,
            TenantId: 'TIN7532204',
            ResidentStatusID: 1,
            ResidentStatusName: 'Future',
            ResidentRelationshipID: 1,
            ResidentRelationshipName: 'Head of Household',
            FirstName: 'Abigail',
            LastName: 'Corral',
            Email: 'conference+42242@movingin.com',
            Phone: '602-791-8802',
            DateLeaseStart: '2023-01-20T00:00:00',
            DateLeaseRenewal: '2024-04-20T00:00:00',
            Created: '2022-10-20T22:52:15.287',
            CreatedBy: 'kurt@movingin.com',
            Updated: null,
            UpdatedBy: null,
            InvitationID: 73,
            InvitationLink:
              'https://www.movingin.com/dashboard?invite=M0YyMTQxM0UtMzFENS00RjkwLUEyNzktNjg3RDkwREVBNjFG',
            HasInvitation: true,
            DateInvitationCreated: '2023-01-20T16:59:42.263',
            DateInvitationDelivered: '2023-01-20T17:00:02.62',
            DateInvitationRead: '2023-01-10T17:03:39.503',
            DateInvitationClicked: '2023-01-15T17:03:39.503',
            InvitationUrlClicked:
              'https://www.movingin.com/dashboard?invite=M0YyMTQxM0UtMzFENS00RjkwLUEyNzktNjg3RDkwREVBNjFG',
            DateInvitationDropped: null,
            InvitationDropReason: null,
            DateInvitationVisited: '2022-11-04T17:03:34.31',
            ServiceAddressID: 8390636,
            DisplayOrder: 1,
            Grouping: 'provider-service',
            Description: 'Electric Service',
            ResidentOnboardingStatusID: 3,
            Reason: '',
            ProviderServiceID: 9,
            ResidentVehicleID: null,
            ResidentPetID: null,
            OnboardingColumn: 'Electric',
            ResidentOnboardingStatusName: 'Completed',
            ServiceProfileID: 56,
            CountBedroom: null,
            CountBathroom: null,
            SquareFootage: null,
            DaysToLeaseStart: 0,
            Previous_Address: null,
            Previous_Secondary: null,
            Previous_City: null,
            Previous_State: null,
            Previous_Zip: null,
            IsResidentOnboarding: 1,
            DateLastUpdated: '2023-01-20T21:52:05.243',
            ExportFileName: 'TIN7532204_ElectricPOS.pdf'
          }
        ],
        DateLastUpdated: '2023-01-20T21:52:05.243',
        ExportFileName: 'TIN7532204_ElectricPOS.pdf'
      },
      'Natural Gas': {
        ResidentOnboardingStatusID: 3,
        ResidentOnboardingStatusName: 'Completed',
        Reason: '',
        ProviderServiceList: [
          {
            PropertyID: 166313,
            PropertyName: 'Camelback Square',
            UnitNumber: '2018',
            Address: '1714 21st St',
            Secondary: 'Apt 2018',
            City: 'Sacramento',
            State: 'CA',
            Zip: '95811',
            ResidentID: 1006,
            UnitID: 13855,
            TenantId: 'TIN7532204',
            ResidentStatusID: 1,
            ResidentStatusName: 'Future',
            ResidentRelationshipID: 1,
            ResidentRelationshipName: 'Head of Household',
            FirstName: 'Abigail',
            LastName: 'Corral',
            Email: 'conference+42242@movingin.com',
            Phone: '602-791-8802',
            DateLeaseStart: '2023-01-20T00:00:00',
            DateLeaseRenewal: '2024-04-20T00:00:00',
            Created: '2022-10-20T22:52:15.287',
            CreatedBy: 'kurt@movingin.com',
            Updated: null,
            UpdatedBy: null,
            InvitationID: 73,
            InvitationLink:
              'https://www.movingin.com/dashboard?invite=M0YyMTQxM0UtMzFENS00RjkwLUEyNzktNjg3RDkwREVBNjFG',
            HasInvitation: true,
            DateInvitationCreated: '2023-01-20T16:59:42.263',
            DateInvitationDelivered: '2023-01-20T17:00:02.62',
            DateInvitationRead: '2023-01-10T17:03:39.503',
            DateInvitationClicked: '2023-01-15T17:03:39.503',
            InvitationUrlClicked:
              'https://www.movingin.com/dashboard?invite=M0YyMTQxM0UtMzFENS00RjkwLUEyNzktNjg3RDkwREVBNjFG',
            DateInvitationDropped: null,
            InvitationDropReason: null,
            DateInvitationVisited: '2022-11-04T17:03:34.31',
            ServiceAddressID: 8390636,
            DisplayOrder: 2,
            Grouping: 'provider-service',
            Description: 'Natural Gas Service',
            ResidentOnboardingStatusID: 3,
            Reason: '',
            ProviderServiceID: 8,
            ResidentVehicleID: null,
            ResidentPetID: null,
            OnboardingColumn: 'Natural Gas',
            ResidentOnboardingStatusName: 'Completed',
            ServiceProfileID: 56,
            CountBedroom: null,
            CountBathroom: null,
            SquareFootage: null,
            DaysToLeaseStart: 0,
            Previous_Address: null,
            Previous_Secondary: null,
            Previous_City: null,
            Previous_State: null,
            Previous_Zip: null,
            IsResidentOnboarding: 1,
            DateLastUpdated: '2023-01-20T21:52:33.747',
            ExportFileName: 'TIN7532204_NaturalGasPOS.pdf'
          }
        ],
        DateLastUpdated: '2023-01-20T21:52:33.747',
        ExportFileName: 'TIN7532204_NaturalGasPOS.pdf'
      },
      'Renters Insurance': {
        ResidentOnboardingStatusID: 3,
        ResidentOnboardingStatusName: 'Completed',
        Reason: '',
        ProviderServiceList: [
          {
            PropertyID: 166313,
            PropertyName: 'Camelback Square',
            UnitNumber: '2018',
            Address: '1714 21st St',
            Secondary: 'Apt 2018',
            City: 'Sacramento',
            State: 'CA',
            Zip: '95811',
            ResidentID: 1006,
            UnitID: 13855,
            TenantId: 'TIN7532204',
            ResidentStatusID: 1,
            ResidentStatusName: 'Future',
            ResidentRelationshipID: 1,
            ResidentRelationshipName: 'Head of Household',
            FirstName: 'Abigail',
            LastName: 'Corral',
            Email: 'conference+42242@movingin.com',
            Phone: '602-791-8802',
            DateLeaseStart: '2023-01-20T00:00:00',
            DateLeaseRenewal: '2024-04-20T00:00:00',
            Created: '2022-10-20T22:52:15.287',
            CreatedBy: 'kurt@movingin.com',
            Updated: null,
            UpdatedBy: null,
            InvitationID: 73,
            InvitationLink:
              'https://www.movingin.com/dashboard?invite=M0YyMTQxM0UtMzFENS00RjkwLUEyNzktNjg3RDkwREVBNjFG',
            HasInvitation: true,
            DateInvitationCreated: '2023-01-20T16:59:42.263',
            DateInvitationDelivered: '2023-01-20T17:00:02.62',
            DateInvitationRead: '2023-01-10T17:03:39.503',
            DateInvitationClicked: '2023-01-15T17:03:39.503',
            InvitationUrlClicked:
              'https://www.movingin.com/dashboard?invite=M0YyMTQxM0UtMzFENS00RjkwLUEyNzktNjg3RDkwREVBNjFG',
            DateInvitationDropped: null,
            InvitationDropReason: null,
            DateInvitationVisited: '2022-11-04T17:03:34.31',
            ServiceAddressID: 8390636,
            DisplayOrder: 3,
            Grouping: 'provider-service',
            Description: 'Renters Insurance',
            ResidentOnboardingStatusID: 3,
            Reason: '',
            ProviderServiceID: 37,
            ResidentVehicleID: null,
            ResidentPetID: null,
            OnboardingColumn: 'Renters Insurance',
            ResidentOnboardingStatusName: 'Completed',
            ServiceProfileID: 56,
            CountBedroom: null,
            CountBathroom: null,
            SquareFootage: null,
            DaysToLeaseStart: 0,
            Previous_Address: null,
            Previous_Secondary: null,
            Previous_City: null,
            Previous_State: null,
            Previous_Zip: null,
            IsResidentOnboarding: 1,
            DateLastUpdated: '2023-01-20T21:53:13.877',
            ExportFileName: 'TIN7532204_RentersInsurancePOS.pdf'
          }
        ],
        DateLastUpdated: '2023-01-20T21:53:13.877',
        ExportFileName: 'TIN7532204_RentersInsurancePOS.pdf'
      },
      'Vehicle Registration': {
        ResidentOnboardingStatusID: 3,
        ResidentOnboardingStatusName: 'Completed',
        Reason: '',
        ProviderServiceList: [],
        DateLastUpdated: '2023-01-20T20:50:41.6',
        ExportFileName: 'TIN7532204_VehicleReg.pdf',
        Grouping: 'vehicle'
      },
      'Pet Registration': {
        ResidentOnboardingStatusID: 3,
        ResidentOnboardingStatusName: 'Completed',
        Reason: '',
        ProviderServiceList: [],
        DateLastUpdated: '2023-01-20T16:30:45.897',
        ExportFileName: 'TIN7532204_PetReg.pdf',
        Grouping: 'pet'
      },
      'Move-in Condition Form': {
        ResidentOnboardingStatusID: 0,
        ResidentOnboardingStatusName: 'Sent 01/20/23',
        Reason: '',
        ProviderServiceList: [],
        DateLastUpdated: null,
        ExportFileName: null,
        Grouping: 'walkthrough'
      }
    }
  },
  {
    PropertyID: 166313,
    PropertyName: 'Camelback Square',
    UnitNumber: '1043',
    Address: '1714 21st St',
    Secondary: 'Apt 1043',
    City: 'Sacramento',
    State: 'CA',
    Zip: '95811',
    ResidentID: 1060,
    UnitID: 13712,
    TenantId: 'TIN7936517',
    ResidentStatusID: 1,
    ResidentStatusName: 'Future',
    ResidentRelationshipID: 1,
    ResidentRelationshipName: 'Head of Household',
    FirstName: 'Jamie',
    LastName: 'Brewer',
    Email: 'conference+42353@movingin.com',
    Phone: '602-791-8802',
    DateLeaseStart: '2023-01-21T00:00:00',
    DateLeaseRenewal: '2024-04-21T00:00:00',
    Created: '2022-10-20T22:52:15.287',
    CreatedBy: 'kurt@movingin.com',
    Updated: null,
    UpdatedBy: null,
    InvitationID: 72,
    InvitationLink:
      'https://www.movingin.com/dashboard?invite=MDRFQTRFRDEtQ0M0RS00N0Y1LUFBRTEtQTFFRTVGM0JENUMz',
    HasInvitation: true,
    DateInvitationCreated: '2023-01-20T16:59:22.08',
    DateInvitationDelivered: '2023-01-20T16:59:31.147',
    DateInvitationRead: '2023-01-10T17:03:27.3',
    DateInvitationClicked: '2023-01-15T17:03:27.3',
    InvitationUrlClicked:
      'https://www.movingin.com/dashboard?invite=MDRFQTRFRDEtQ0M0RS00N0Y1LUFBRTEtQTFFRTVGM0JENUMz',
    DateInvitationDropped: null,
    InvitationDropReason: null,
    DateInvitationVisited: '2022-11-04T17:03:27.473',
    ServiceAddressID: 8390493,
    DisplayOrder: 1,
    Grouping: 'provider-service',
    Description: 'Electric Service',
    ResidentOnboardingStatusID: 3,
    Reason: '',
    ProviderServiceID: 9,
    ResidentVehicleID: null,
    ResidentPetID: null,
    OnboardingColumn: 'Electric',
    ResidentOnboardingStatusName: 'Completed',
    ServiceProfileID: 56,
    CountBedroom: null,
    CountBathroom: null,
    SquareFootage: null,
    DaysToLeaseStart: 1,
    Previous_Address: null,
    Previous_Secondary: null,
    Previous_City: null,
    Previous_State: null,
    Previous_Zip: null,
    IsResidentOnboarding: 1,
    DateLastUpdated: '2023-01-20T21:53:54.547',
    ExportFileName: 'TIN7936517_ElectricPOS.pdf',
    data: {
      Electric: {
        ResidentOnboardingStatusID: 3,
        ResidentOnboardingStatusName: 'Completed',
        Reason: '',
        ProviderServiceList: [
          {
            PropertyID: 166313,
            PropertyName: 'Camelback Square',
            UnitNumber: '1043',
            Address: '1714 21st St',
            Secondary: 'Apt 1043',
            City: 'Sacramento',
            State: 'CA',
            Zip: '95811',
            ResidentID: 1060,
            UnitID: 13712,
            TenantId: 'TIN7936517',
            ResidentStatusID: 1,
            ResidentStatusName: 'Future',
            ResidentRelationshipID: 1,
            ResidentRelationshipName: 'Head of Household',
            FirstName: 'Jamie',
            LastName: 'Brewer',
            Email: 'conference+42353@movingin.com',
            Phone: '602-791-8802',
            DateLeaseStart: '2023-01-21T00:00:00',
            DateLeaseRenewal: '2024-04-21T00:00:00',
            Created: '2022-10-20T22:52:15.287',
            CreatedBy: 'kurt@movingin.com',
            Updated: null,
            UpdatedBy: null,
            InvitationID: 72,
            InvitationLink:
              'https://www.movingin.com/dashboard?invite=MDRFQTRFRDEtQ0M0RS00N0Y1LUFBRTEtQTFFRTVGM0JENUMz',
            HasInvitation: true,
            DateInvitationCreated: '2023-01-20T16:59:22.08',
            DateInvitationDelivered: '2023-01-20T16:59:31.147',
            DateInvitationRead: '2023-01-10T17:03:27.3',
            DateInvitationClicked: '2023-01-15T17:03:27.3',
            InvitationUrlClicked:
              'https://www.movingin.com/dashboard?invite=MDRFQTRFRDEtQ0M0RS00N0Y1LUFBRTEtQTFFRTVGM0JENUMz',
            DateInvitationDropped: null,
            InvitationDropReason: null,
            DateInvitationVisited: '2022-11-04T17:03:27.473',
            ServiceAddressID: 8390493,
            DisplayOrder: 1,
            Grouping: 'provider-service',
            Description: 'Electric Service',
            ResidentOnboardingStatusID: 3,
            Reason: '',
            ProviderServiceID: 9,
            ResidentVehicleID: null,
            ResidentPetID: null,
            OnboardingColumn: 'Electric',
            ResidentOnboardingStatusName: 'Completed',
            ServiceProfileID: 56,
            CountBedroom: null,
            CountBathroom: null,
            SquareFootage: null,
            DaysToLeaseStart: 1,
            Previous_Address: null,
            Previous_Secondary: null,
            Previous_City: null,
            Previous_State: null,
            Previous_Zip: null,
            IsResidentOnboarding: 1,
            DateLastUpdated: '2023-01-20T21:53:54.547',
            ExportFileName: 'TIN7936517_ElectricPOS.pdf'
          }
        ],
        DateLastUpdated: '2023-01-20T21:53:54.547',
        ExportFileName: 'TIN7936517_ElectricPOS.pdf'
      },
      'Natural Gas': {
        ResidentOnboardingStatusID: 3,
        ResidentOnboardingStatusName: 'Completed',
        Reason: '',
        ProviderServiceList: [
          {
            PropertyID: 166313,
            PropertyName: 'Camelback Square',
            UnitNumber: '1043',
            Address: '1714 21st St',
            Secondary: 'Apt 1043',
            City: 'Sacramento',
            State: 'CA',
            Zip: '95811',
            ResidentID: 1060,
            UnitID: 13712,
            TenantId: 'TIN7936517',
            ResidentStatusID: 1,
            ResidentStatusName: 'Future',
            ResidentRelationshipID: 1,
            ResidentRelationshipName: 'Head of Household',
            FirstName: 'Jamie',
            LastName: 'Brewer',
            Email: 'conference+42353@movingin.com',
            Phone: '602-791-8802',
            DateLeaseStart: '2023-01-21T00:00:00',
            DateLeaseRenewal: '2024-04-21T00:00:00',
            Created: '2022-10-20T22:52:15.287',
            CreatedBy: 'kurt@movingin.com',
            Updated: null,
            UpdatedBy: null,
            InvitationID: 72,
            InvitationLink:
              'https://www.movingin.com/dashboard?invite=MDRFQTRFRDEtQ0M0RS00N0Y1LUFBRTEtQTFFRTVGM0JENUMz',
            HasInvitation: true,
            DateInvitationCreated: '2023-01-20T16:59:22.08',
            DateInvitationDelivered: '2023-01-20T16:59:31.147',
            DateInvitationRead: '2023-01-10T17:03:27.3',
            DateInvitationClicked: '2023-01-15T17:03:27.3',
            InvitationUrlClicked:
              'https://www.movingin.com/dashboard?invite=MDRFQTRFRDEtQ0M0RS00N0Y1LUFBRTEtQTFFRTVGM0JENUMz',
            DateInvitationDropped: null,
            InvitationDropReason: null,
            DateInvitationVisited: '2022-11-04T17:03:27.473',
            ServiceAddressID: 8390493,
            DisplayOrder: 2,
            Grouping: 'provider-service',
            Description: 'Natural Gas Service',
            ResidentOnboardingStatusID: 3,
            Reason: '',
            ProviderServiceID: 8,
            ResidentVehicleID: null,
            ResidentPetID: null,
            OnboardingColumn: 'Natural Gas',
            ResidentOnboardingStatusName: 'Completed',
            ServiceProfileID: 56,
            CountBedroom: null,
            CountBathroom: null,
            SquareFootage: null,
            DaysToLeaseStart: 1,
            Previous_Address: null,
            Previous_Secondary: null,
            Previous_City: null,
            Previous_State: null,
            Previous_Zip: null,
            IsResidentOnboarding: 1,
            DateLastUpdated: '2023-01-20T21:54:19.977',
            ExportFileName: 'TIN7936517_NaturalGasPOS.pdf'
          }
        ],
        DateLastUpdated: '2023-01-20T21:54:19.977',
        ExportFileName: 'TIN7936517_NaturalGasPOS.pdf'
      },
      'Renters Insurance': {
        ResidentOnboardingStatusID: 3,
        ResidentOnboardingStatusName: 'Completed',
        Reason: '',
        ProviderServiceList: [
          {
            PropertyID: 166313,
            PropertyName: 'Camelback Square',
            UnitNumber: '1043',
            Address: '1714 21st St',
            Secondary: 'Apt 1043',
            City: 'Sacramento',
            State: 'CA',
            Zip: '95811',
            ResidentID: 1060,
            UnitID: 13712,
            TenantId: 'TIN7936517',
            ResidentStatusID: 1,
            ResidentStatusName: 'Future',
            ResidentRelationshipID: 1,
            ResidentRelationshipName: 'Head of Household',
            FirstName: 'Jamie',
            LastName: 'Brewer',
            Email: 'conference+42353@movingin.com',
            Phone: '602-791-8802',
            DateLeaseStart: '2023-01-21T00:00:00',
            DateLeaseRenewal: '2024-04-21T00:00:00',
            Created: '2022-10-20T22:52:15.287',
            CreatedBy: 'kurt@movingin.com',
            Updated: null,
            UpdatedBy: null,
            InvitationID: 72,
            InvitationLink:
              'https://www.movingin.com/dashboard?invite=MDRFQTRFRDEtQ0M0RS00N0Y1LUFBRTEtQTFFRTVGM0JENUMz',
            HasInvitation: true,
            DateInvitationCreated: '2023-01-20T16:59:22.08',
            DateInvitationDelivered: '2023-01-20T16:59:31.147',
            DateInvitationRead: '2023-01-10T17:03:27.3',
            DateInvitationClicked: '2023-01-15T17:03:27.3',
            InvitationUrlClicked:
              'https://www.movingin.com/dashboard?invite=MDRFQTRFRDEtQ0M0RS00N0Y1LUFBRTEtQTFFRTVGM0JENUMz',
            DateInvitationDropped: null,
            InvitationDropReason: null,
            DateInvitationVisited: '2022-11-04T17:03:27.473',
            ServiceAddressID: 8390493,
            DisplayOrder: 3,
            Grouping: 'provider-service',
            Description: 'Renters Insurance',
            ResidentOnboardingStatusID: 3,
            Reason: '',
            ProviderServiceID: 37,
            ResidentVehicleID: null,
            ResidentPetID: null,
            OnboardingColumn: 'Renters Insurance',
            ResidentOnboardingStatusName: 'Completed',
            ServiceProfileID: 56,
            CountBedroom: null,
            CountBathroom: null,
            SquareFootage: null,
            DaysToLeaseStart: 1,
            Previous_Address: null,
            Previous_Secondary: null,
            Previous_City: null,
            Previous_State: null,
            Previous_Zip: null,
            IsResidentOnboarding: 1,
            DateLastUpdated: '2023-01-20T21:54:49.347',
            ExportFileName: 'TIN7936517_RentersInsurancePOS.pdf'
          }
        ],
        DateLastUpdated: '2023-01-20T21:54:49.347',
        ExportFileName: 'TIN7936517_RentersInsurancePOS.pdf'
      },
      'Vehicle Registration': {
        ResidentOnboardingStatusID: 3,
        ResidentOnboardingStatusName: 'Completed',
        Reason: '',
        ProviderServiceList: [],
        DateLastUpdated: '2023-01-20T21:37:17.497',
        ExportFileName: 'TIN7936517_VehicleReg.pdf',
        Grouping: 'vehicle'
      },
      'Pet Registration': {
        ResidentOnboardingStatusID: 3,
        ResidentOnboardingStatusName: 'Completed',
        Reason: '',
        ProviderServiceList: [],
        DateLastUpdated: '2023-01-20T16:31:08.72',
        ExportFileName: 'TIN7936517_PetReg.pdf',
        Grouping: 'pet'
      },
      'Move-in Condition Form': {
        ResidentOnboardingStatusID: 0,
        ResidentOnboardingStatusName: 'Available 01/21/23',
        Reason: '',
        ProviderServiceList: [],
        DateLastUpdated: null,
        ExportFileName: null,
        Grouping: 'walkthrough'
      }
    }
  },
  {
    PropertyID: 166313,
    PropertyName: 'Camelback Square',
    UnitNumber: '2161',
    Address: '1714 21st St',
    Secondary: 'Apt 2161',
    City: 'Sacramento',
    State: 'CA',
    Zip: '95811',
    ResidentID: 1138,
    UnitID: 13998,
    TenantId: 'TIN8520525',
    ResidentStatusID: 1,
    ResidentStatusName: 'Future',
    ResidentRelationshipID: 1,
    ResidentRelationshipName: 'Head of Household',
    FirstName: 'Paige',
    LastName: 'Smotherman',
    Email: 'conference+32157@movingin.com',
    Phone: '602-791-8802',
    DateLeaseStart: '2023-01-21T00:00:00',
    DateLeaseRenewal: '2023-12-13T00:00:00',
    Created: '2022-10-20T22:52:15.287',
    CreatedBy: 'kurt@movingin.com',
    Updated: null,
    UpdatedBy: null,
    InvitationID: 71,
    InvitationLink:
      'https://www.movingin.com/dashboard?invite=Qjc0ODNEQTItMDVCMy00NkZBLUE0MTYtRDJBQ0I5NkJFODE5',
    HasInvitation: true,
    DateInvitationCreated: '2023-01-20T16:59:04.143',
    DateInvitationDelivered: '2023-01-20T16:59:14.973',
    DateInvitationRead: '2023-01-20T17:03:03.393',
    DateInvitationClicked: '2023-01-20T17:03:26.237',
    InvitationUrlClicked:
      'https://www.movingin.com/dashboard?invite=Qjc0ODNEQTItMDVCMy00NkZBLUE0MTYtRDJBQ0I5NkJFODE5',
    DateInvitationDropped: null,
    InvitationDropReason: null,
    DateInvitationVisited: '2022-11-06T17:03:19.06',
    ServiceAddressID: 8390779,
    DisplayOrder: 1,
    Grouping: 'provider-service',
    Description: 'Electric Service',
    ResidentOnboardingStatusID: 3,
    Reason: '',
    ProviderServiceID: 9,
    ResidentVehicleID: null,
    ResidentPetID: null,
    OnboardingColumn: 'Electric',
    ResidentOnboardingStatusName: 'Completed',
    ServiceProfileID: 56,
    CountBedroom: null,
    CountBathroom: null,
    SquareFootage: null,
    DaysToLeaseStart: 1,
    Previous_Address: null,
    Previous_Secondary: null,
    Previous_City: null,
    Previous_State: null,
    Previous_Zip: null,
    IsResidentOnboarding: 1,
    DateLastUpdated: '2023-01-20T21:55:22.537',
    ExportFileName: 'TIN8520525_ElectricPOS.pdf',
    data: {
      Electric: {
        ResidentOnboardingStatusID: 3,
        ResidentOnboardingStatusName: 'Completed',
        Reason: '',
        ProviderServiceList: [
          {
            PropertyID: 166313,
            PropertyName: 'Camelback Square',
            UnitNumber: '2161',
            Address: '1714 21st St',
            Secondary: 'Apt 2161',
            City: 'Sacramento',
            State: 'CA',
            Zip: '95811',
            ResidentID: 1138,
            UnitID: 13998,
            TenantId: 'TIN8520525',
            ResidentStatusID: 1,
            ResidentStatusName: 'Future',
            ResidentRelationshipID: 1,
            ResidentRelationshipName: 'Head of Household',
            FirstName: 'Paige',
            LastName: 'Smotherman',
            Email: 'conference+32157@movingin.com',
            Phone: '602-791-8802',
            DateLeaseStart: '2023-01-21T00:00:00',
            DateLeaseRenewal: '2023-12-13T00:00:00',
            Created: '2022-10-20T22:52:15.287',
            CreatedBy: 'kurt@movingin.com',
            Updated: null,
            UpdatedBy: null,
            InvitationID: 71,
            InvitationLink:
              'https://www.movingin.com/dashboard?invite=Qjc0ODNEQTItMDVCMy00NkZBLUE0MTYtRDJBQ0I5NkJFODE5',
            HasInvitation: true,
            DateInvitationCreated: '2023-01-20T16:59:04.143',
            DateInvitationDelivered: '2023-01-20T16:59:14.973',
            DateInvitationRead: '2023-01-20T17:03:03.393',
            DateInvitationClicked: '2023-01-20T17:03:26.237',
            InvitationUrlClicked:
              'https://www.movingin.com/dashboard?invite=Qjc0ODNEQTItMDVCMy00NkZBLUE0MTYtRDJBQ0I5NkJFODE5',
            DateInvitationDropped: null,
            InvitationDropReason: null,
            DateInvitationVisited: '2022-11-06T17:03:19.06',
            ServiceAddressID: 8390779,
            DisplayOrder: 1,
            Grouping: 'provider-service',
            Description: 'Electric Service',
            ResidentOnboardingStatusID: 3,
            Reason: '',
            ProviderServiceID: 9,
            ResidentVehicleID: null,
            ResidentPetID: null,
            OnboardingColumn: 'Electric',
            ResidentOnboardingStatusName: 'Completed',
            ServiceProfileID: 56,
            CountBedroom: null,
            CountBathroom: null,
            SquareFootage: null,
            DaysToLeaseStart: 1,
            Previous_Address: null,
            Previous_Secondary: null,
            Previous_City: null,
            Previous_State: null,
            Previous_Zip: null,
            IsResidentOnboarding: 1,
            DateLastUpdated: '2023-01-20T21:55:22.537',
            ExportFileName: 'TIN8520525_ElectricPOS.pdf'
          }
        ],
        DateLastUpdated: '2023-01-20T21:55:22.537',
        ExportFileName: 'TIN8520525_ElectricPOS.pdf'
      },
      'Natural Gas': {
        ResidentOnboardingStatusID: 3,
        ResidentOnboardingStatusName: 'Completed',
        Reason: '',
        ProviderServiceList: [
          {
            PropertyID: 166313,
            PropertyName: 'Camelback Square',
            UnitNumber: '2161',
            Address: '1714 21st St',
            Secondary: 'Apt 2161',
            City: 'Sacramento',
            State: 'CA',
            Zip: '95811',
            ResidentID: 1138,
            UnitID: 13998,
            TenantId: 'TIN8520525',
            ResidentStatusID: 1,
            ResidentStatusName: 'Future',
            ResidentRelationshipID: 1,
            ResidentRelationshipName: 'Head of Household',
            FirstName: 'Paige',
            LastName: 'Smotherman',
            Email: 'conference+32157@movingin.com',
            Phone: '602-791-8802',
            DateLeaseStart: '2023-01-21T00:00:00',
            DateLeaseRenewal: '2023-12-13T00:00:00',
            Created: '2022-10-20T22:52:15.287',
            CreatedBy: 'kurt@movingin.com',
            Updated: null,
            UpdatedBy: null,
            InvitationID: 71,
            InvitationLink:
              'https://www.movingin.com/dashboard?invite=Qjc0ODNEQTItMDVCMy00NkZBLUE0MTYtRDJBQ0I5NkJFODE5',
            HasInvitation: true,
            DateInvitationCreated: '2023-01-20T16:59:04.143',
            DateInvitationDelivered: '2023-01-20T16:59:14.973',
            DateInvitationRead: '2023-01-20T17:03:03.393',
            DateInvitationClicked: '2023-01-20T17:03:26.237',
            InvitationUrlClicked:
              'https://www.movingin.com/dashboard?invite=Qjc0ODNEQTItMDVCMy00NkZBLUE0MTYtRDJBQ0I5NkJFODE5',
            DateInvitationDropped: null,
            InvitationDropReason: null,
            DateInvitationVisited: '2022-11-06T17:03:19.06',
            ServiceAddressID: 8390779,
            DisplayOrder: 2,
            Grouping: 'provider-service',
            Description: 'Natural Gas Service',
            ResidentOnboardingStatusID: 3,
            Reason: '',
            ProviderServiceID: 8,
            ResidentVehicleID: null,
            ResidentPetID: null,
            OnboardingColumn: 'Natural Gas',
            ResidentOnboardingStatusName: 'Completed',
            ServiceProfileID: 56,
            CountBedroom: null,
            CountBathroom: null,
            SquareFootage: null,
            DaysToLeaseStart: 1,
            Previous_Address: null,
            Previous_Secondary: null,
            Previous_City: null,
            Previous_State: null,
            Previous_Zip: null,
            IsResidentOnboarding: 1,
            DateLastUpdated: '2023-01-20T21:56:23.757',
            ExportFileName: 'TIN8520525_NaturalGasPOS.pdf'
          }
        ],
        DateLastUpdated: '2023-01-20T21:56:23.757',
        ExportFileName: 'TIN8520525_NaturalGasPOS.pdf'
      },
      'Renters Insurance': {
        ResidentOnboardingStatusID: 3,
        ResidentOnboardingStatusName: 'Completed',
        Reason: '',
        ProviderServiceList: [
          {
            PropertyID: 166313,
            PropertyName: 'Camelback Square',
            UnitNumber: '2161',
            Address: '1714 21st St',
            Secondary: 'Apt 2161',
            City: 'Sacramento',
            State: 'CA',
            Zip: '95811',
            ResidentID: 1138,
            UnitID: 13998,
            TenantId: 'TIN8520525',
            ResidentStatusID: 1,
            ResidentStatusName: 'Future',
            ResidentRelationshipID: 1,
            ResidentRelationshipName: 'Head of Household',
            FirstName: 'Paige',
            LastName: 'Smotherman',
            Email: 'conference+32157@movingin.com',
            Phone: '602-791-8802',
            DateLeaseStart: '2023-01-21T00:00:00',
            DateLeaseRenewal: '2023-12-13T00:00:00',
            Created: '2022-10-20T22:52:15.287',
            CreatedBy: 'kurt@movingin.com',
            Updated: null,
            UpdatedBy: null,
            InvitationID: 71,
            InvitationLink:
              'https://www.movingin.com/dashboard?invite=Qjc0ODNEQTItMDVCMy00NkZBLUE0MTYtRDJBQ0I5NkJFODE5',
            HasInvitation: true,
            DateInvitationCreated: '2023-01-20T16:59:04.143',
            DateInvitationDelivered: '2023-01-20T16:59:14.973',
            DateInvitationRead: '2023-01-20T17:03:03.393',
            DateInvitationClicked: '2023-01-20T17:03:26.237',
            InvitationUrlClicked:
              'https://www.movingin.com/dashboard?invite=Qjc0ODNEQTItMDVCMy00NkZBLUE0MTYtRDJBQ0I5NkJFODE5',
            DateInvitationDropped: null,
            InvitationDropReason: null,
            DateInvitationVisited: '2022-11-06T17:03:19.06',
            ServiceAddressID: 8390779,
            DisplayOrder: 3,
            Grouping: 'provider-service',
            Description: 'Renters Insurance',
            ResidentOnboardingStatusID: 3,
            Reason: '',
            ProviderServiceID: 37,
            ResidentVehicleID: null,
            ResidentPetID: null,
            OnboardingColumn: 'Renters Insurance',
            ResidentOnboardingStatusName: 'Completed',
            ServiceProfileID: 56,
            CountBedroom: null,
            CountBathroom: null,
            SquareFootage: null,
            DaysToLeaseStart: 1,
            Previous_Address: null,
            Previous_Secondary: null,
            Previous_City: null,
            Previous_State: null,
            Previous_Zip: null,
            IsResidentOnboarding: 1,
            DateLastUpdated: '2023-01-20T21:57:01.727',
            ExportFileName: 'TIN8520525_RentersInsurancePOS.pdf'
          }
        ],
        DateLastUpdated: '2023-01-20T21:57:01.727',
        ExportFileName: 'TIN8520525_RentersInsurancePOS.pdf'
      },
      'Vehicle Registration': {
        ResidentOnboardingStatusID: 3,
        ResidentOnboardingStatusName: 'Completed',
        Reason: '',
        ProviderServiceList: [],
        DateLastUpdated: '2023-01-20T18:24:47.55',
        ExportFileName: 'TIN8520525_VehicleReg.pdf',
        Grouping: 'vehicle'
      },
      'Pet Registration': {
        ResidentOnboardingStatusID: 3,
        ResidentOnboardingStatusName: 'Completed',
        Reason: '',
        ProviderServiceList: [],
        DateLastUpdated: '2023-01-20T16:32:40.017',
        ExportFileName: 'TIN8520525_PetReg.pdf',
        Grouping: 'pet'
      },
      'Move-in Condition Form': {
        ResidentOnboardingStatusID: 0,
        ResidentOnboardingStatusName: 'Available 01/21/23',
        Reason: '',
        ProviderServiceList: [],
        DateLastUpdated: null,
        ExportFileName: null,
        Grouping: 'walkthrough'
      }
    }
  },
  {
    PropertyID: 166313,
    PropertyName: 'Camelback Square',
    UnitNumber: '1144',
    Address: '1714 21st St',
    Secondary: 'Apt 1144',
    City: 'Sacramento',
    State: 'CA',
    Zip: '95811',
    ResidentID: 1074,
    UnitID: 13813,
    TenantId: 'TIN8041339',
    ResidentStatusID: 1,
    ResidentStatusName: 'Future',
    ResidentRelationshipID: 1,
    ResidentRelationshipName: 'Head of Household',
    FirstName: 'Emily',
    LastName: 'Austin',
    Email: 'conference+32159@movingin.com',
    Phone: '602-791-8802',
    DateLeaseStart: '2023-01-25T00:00:00',
    DateLeaseRenewal: '2024-02-24T00:00:00',
    Created: '2022-10-20T22:52:15.287',
    CreatedBy: 'kurt@movingin.com',
    Updated: null,
    UpdatedBy: null,
    InvitationID: 70,
    InvitationLink:
      'https://www.movingin.com/dashboard?invite=NTBEOTc0MTctODRGMC00MjZELTlBQzQtQzFGNEZERkEyNUNF',
    HasInvitation: true,
    DateInvitationCreated: '2023-01-20T16:58:33.603',
    DateInvitationDelivered: '2023-01-20T16:58:45.17',
    DateInvitationRead: '2023-01-20T17:03:06.237',
    DateInvitationClicked: '2023-01-20T17:03:11.817',
    InvitationUrlClicked:
      'https://www.movingin.com/dashboard?invite=NTBEOTc0MTctODRGMC00MjZELTlBQzQtQzFGNEZERkEyNUNF',
    DateInvitationDropped: null,
    InvitationDropReason: null,
    DateInvitationVisited: '2022-11-07T17:03:09.207',
    ServiceAddressID: 8390594,
    DisplayOrder: 1,
    Grouping: 'provider-service',
    Description: 'Electric Service',
    ResidentOnboardingStatusID: 3,
    Reason: '',
    ProviderServiceID: 9,
    ResidentVehicleID: null,
    ResidentPetID: null,
    OnboardingColumn: 'Electric',
    ResidentOnboardingStatusName: 'Completed',
    ServiceProfileID: 56,
    CountBedroom: null,
    CountBathroom: null,
    SquareFootage: null,
    DaysToLeaseStart: 5,
    Previous_Address: null,
    Previous_Secondary: null,
    Previous_City: null,
    Previous_State: null,
    Previous_Zip: null,
    IsResidentOnboarding: 1,
    DateLastUpdated: '2023-01-20T21:57:28.88',
    ExportFileName: 'TIN8041339_ElectricPOS.pdf',
    data: {
      Electric: {
        ResidentOnboardingStatusID: 3,
        ResidentOnboardingStatusName: 'Completed',
        Reason: '',
        ProviderServiceList: [
          {
            PropertyID: 166313,
            PropertyName: 'Camelback Square',
            UnitNumber: '1144',
            Address: '1714 21st St',
            Secondary: 'Apt 1144',
            City: 'Sacramento',
            State: 'CA',
            Zip: '95811',
            ResidentID: 1074,
            UnitID: 13813,
            TenantId: 'TIN8041339',
            ResidentStatusID: 1,
            ResidentStatusName: 'Future',
            ResidentRelationshipID: 1,
            ResidentRelationshipName: 'Head of Household',
            FirstName: 'Emily',
            LastName: 'Austin',
            Email: 'conference+32159@movingin.com',
            Phone: '602-791-8802',
            DateLeaseStart: '2023-01-25T00:00:00',
            DateLeaseRenewal: '2024-02-24T00:00:00',
            Created: '2022-10-20T22:52:15.287',
            CreatedBy: 'kurt@movingin.com',
            Updated: null,
            UpdatedBy: null,
            InvitationID: 70,
            InvitationLink:
              'https://www.movingin.com/dashboard?invite=NTBEOTc0MTctODRGMC00MjZELTlBQzQtQzFGNEZERkEyNUNF',
            HasInvitation: true,
            DateInvitationCreated: '2023-01-20T16:58:33.603',
            DateInvitationDelivered: '2023-01-20T16:58:45.17',
            DateInvitationRead: '2023-01-20T17:03:06.237',
            DateInvitationClicked: '2023-01-20T17:03:11.817',
            InvitationUrlClicked:
              'https://www.movingin.com/dashboard?invite=NTBEOTc0MTctODRGMC00MjZELTlBQzQtQzFGNEZERkEyNUNF',
            DateInvitationDropped: null,
            InvitationDropReason: null,
            DateInvitationVisited: '2022-11-07T17:03:09.207',
            ServiceAddressID: 8390594,
            DisplayOrder: 1,
            Grouping: 'provider-service',
            Description: 'Electric Service',
            ResidentOnboardingStatusID: 3,
            Reason: '',
            ProviderServiceID: 9,
            ResidentVehicleID: null,
            ResidentPetID: null,
            OnboardingColumn: 'Electric',
            ResidentOnboardingStatusName: 'Completed',
            ServiceProfileID: 56,
            CountBedroom: null,
            CountBathroom: null,
            SquareFootage: null,
            DaysToLeaseStart: 5,
            Previous_Address: null,
            Previous_Secondary: null,
            Previous_City: null,
            Previous_State: null,
            Previous_Zip: null,
            IsResidentOnboarding: 1,
            DateLastUpdated: '2023-01-20T21:57:28.88',
            ExportFileName: 'TIN8041339_ElectricPOS.pdf'
          }
        ],
        DateLastUpdated: '2023-01-20T21:57:28.88',
        ExportFileName: 'TIN8041339_ElectricPOS.pdf'
      },
      'Natural Gas': {
        ResidentOnboardingStatusID: 3,
        ResidentOnboardingStatusName: 'Completed',
        Reason: '',
        ProviderServiceList: [
          {
            PropertyID: 166313,
            PropertyName: 'Camelback Square',
            UnitNumber: '1144',
            Address: '1714 21st St',
            Secondary: 'Apt 1144',
            City: 'Sacramento',
            State: 'CA',
            Zip: '95811',
            ResidentID: 1074,
            UnitID: 13813,
            TenantId: 'TIN8041339',
            ResidentStatusID: 1,
            ResidentStatusName: 'Future',
            ResidentRelationshipID: 1,
            ResidentRelationshipName: 'Head of Household',
            FirstName: 'Emily',
            LastName: 'Austin',
            Email: 'conference+32159@movingin.com',
            Phone: '602-791-8802',
            DateLeaseStart: '2023-01-25T00:00:00',
            DateLeaseRenewal: '2024-02-24T00:00:00',
            Created: '2022-10-20T22:52:15.287',
            CreatedBy: 'kurt@movingin.com',
            Updated: null,
            UpdatedBy: null,
            InvitationID: 70,
            InvitationLink:
              'https://www.movingin.com/dashboard?invite=NTBEOTc0MTctODRGMC00MjZELTlBQzQtQzFGNEZERkEyNUNF',
            HasInvitation: true,
            DateInvitationCreated: '2023-01-20T16:58:33.603',
            DateInvitationDelivered: '2023-01-20T16:58:45.17',
            DateInvitationRead: '2023-01-20T17:03:06.237',
            DateInvitationClicked: '2023-01-20T17:03:11.817',
            InvitationUrlClicked:
              'https://www.movingin.com/dashboard?invite=NTBEOTc0MTctODRGMC00MjZELTlBQzQtQzFGNEZERkEyNUNF',
            DateInvitationDropped: null,
            InvitationDropReason: null,
            DateInvitationVisited: '2022-11-07T17:03:09.207',
            ServiceAddressID: 8390594,
            DisplayOrder: 2,
            Grouping: 'provider-service',
            Description: 'Natural Gas Service',
            ResidentOnboardingStatusID: 3,
            Reason: '',
            ProviderServiceID: 8,
            ResidentVehicleID: null,
            ResidentPetID: null,
            OnboardingColumn: 'Natural Gas',
            ResidentOnboardingStatusName: 'Completed',
            ServiceProfileID: 56,
            CountBedroom: null,
            CountBathroom: null,
            SquareFootage: null,
            DaysToLeaseStart: 5,
            Previous_Address: null,
            Previous_Secondary: null,
            Previous_City: null,
            Previous_State: null,
            Previous_Zip: null,
            IsResidentOnboarding: 1,
            DateLastUpdated: '2023-01-20T21:57:57.56',
            ExportFileName: 'TIN8041339_NaturalGasPOS.pdf'
          }
        ],
        DateLastUpdated: '2023-01-20T21:57:57.56',
        ExportFileName: 'TIN8041339_NaturalGasPOS.pdf'
      },
      'Renters Insurance': {
        ResidentOnboardingStatusID: 3,
        ResidentOnboardingStatusName: 'Completed',
        Reason: '',
        ProviderServiceList: [
          {
            PropertyID: 166313,
            PropertyName: 'Camelback Square',
            UnitNumber: '1144',
            Address: '1714 21st St',
            Secondary: 'Apt 1144',
            City: 'Sacramento',
            State: 'CA',
            Zip: '95811',
            ResidentID: 1074,
            UnitID: 13813,
            TenantId: 'TIN8041339',
            ResidentStatusID: 1,
            ResidentStatusName: 'Future',
            ResidentRelationshipID: 1,
            ResidentRelationshipName: 'Head of Household',
            FirstName: 'Emily',
            LastName: 'Austin',
            Email: 'conference+32159@movingin.com',
            Phone: '602-791-8802',
            DateLeaseStart: '2023-01-25T00:00:00',
            DateLeaseRenewal: '2024-02-24T00:00:00',
            Created: '2022-10-20T22:52:15.287',
            CreatedBy: 'kurt@movingin.com',
            Updated: null,
            UpdatedBy: null,
            InvitationID: 70,
            InvitationLink:
              'https://www.movingin.com/dashboard?invite=NTBEOTc0MTctODRGMC00MjZELTlBQzQtQzFGNEZERkEyNUNF',
            HasInvitation: true,
            DateInvitationCreated: '2023-01-20T16:58:33.603',
            DateInvitationDelivered: '2023-01-20T16:58:45.17',
            DateInvitationRead: '2023-01-20T17:03:06.237',
            DateInvitationClicked: '2023-01-20T17:03:11.817',
            InvitationUrlClicked:
              'https://www.movingin.com/dashboard?invite=NTBEOTc0MTctODRGMC00MjZELTlBQzQtQzFGNEZERkEyNUNF',
            DateInvitationDropped: null,
            InvitationDropReason: null,
            DateInvitationVisited: '2022-11-07T17:03:09.207',
            ServiceAddressID: 8390594,
            DisplayOrder: 3,
            Grouping: 'provider-service',
            Description: 'Renters Insurance',
            ResidentOnboardingStatusID: 3,
            Reason: '',
            ProviderServiceID: 37,
            ResidentVehicleID: null,
            ResidentPetID: null,
            OnboardingColumn: 'Renters Insurance',
            ResidentOnboardingStatusName: 'Completed',
            ServiceProfileID: 56,
            CountBedroom: null,
            CountBathroom: null,
            SquareFootage: null,
            DaysToLeaseStart: 5,
            Previous_Address: null,
            Previous_Secondary: null,
            Previous_City: null,
            Previous_State: null,
            Previous_Zip: null,
            IsResidentOnboarding: 1,
            DateLastUpdated: '2023-01-20T21:58:29.343',
            ExportFileName: 'TIN8041339_RentersInsurancePOS.pdf'
          }
        ],
        DateLastUpdated: '2023-01-20T21:58:29.343',
        ExportFileName: 'TIN8041339_RentersInsurancePOS.pdf'
      },
      'Vehicle Registration': {
        ResidentOnboardingStatusID: 3,
        ResidentOnboardingStatusName: 'Completed',
        Reason: '',
        ProviderServiceList: [],
        DateLastUpdated: '2023-01-20T16:28:19.87',
        ExportFileName: 'TIN8041339_VehicleReg.pdf',
        Grouping: 'vehicle'
      },
      'Pet Registration': {
        ResidentOnboardingStatusID: 3,
        ResidentOnboardingStatusName: 'Completed',
        Reason: 'Pet Registration,&nbsp;&nbsp;&nbsp;&bull; No pets',
        ProviderServiceList: [],
        DateLastUpdated: null,
        ExportFileName: 'TIN8041339_PetReg.pdf',
        Grouping: 'pet'
      },
      'Move-in Condition Form': {
        ResidentOnboardingStatusID: 0,
        ResidentOnboardingStatusName: 'Available 01/25/23',
        Reason: '',
        ProviderServiceList: [],
        DateLastUpdated: null,
        ExportFileName: null,
        Grouping: 'walkthrough'
      }
    }
  },
  {
    PropertyID: 166313,
    PropertyName: 'Camelback Square',
    UnitNumber: '1101',
    Address: '1714 21st St',
    Secondary: 'Apt 1101',
    City: 'Sacramento',
    State: 'CA',
    Zip: '95811',
    ResidentID: 879,
    UnitID: 13770,
    TenantId: 'TIN6581319',
    ResidentStatusID: 1,
    ResidentStatusName: 'Future',
    ResidentRelationshipID: 1,
    ResidentRelationshipName: 'Head of Household',
    FirstName: 'Isabella',
    LastName: 'Valencia',
    Email: 'conference+42358@movingin.com',
    Phone: '602-791-8802',
    DateLeaseStart: '2023-01-27T00:00:00',
    DateLeaseRenewal: '2023-10-26T00:00:00',
    Created: '2022-10-20T22:52:15.287',
    CreatedBy: 'kurt@movingin.com',
    Updated: null,
    UpdatedBy: null,
    InvitationID: null,
    InvitationLink: null,
    HasInvitation: false,
    DateInvitationCreated: null,
    DateInvitationDelivered: null,
    DateInvitationRead: null,
    DateInvitationClicked: null,
    InvitationUrlClicked: null,
    DateInvitationDropped: null,
    InvitationDropReason: null,
    DateInvitationVisited: null,
    ServiceAddressID: 8390551,
    DisplayOrder: 1,
    Grouping: 'provider-service',
    Description: 'Electric Service',
    ResidentOnboardingStatusID: 2,
    Reason: '',
    ProviderServiceID: 9,
    ResidentVehicleID: null,
    ResidentPetID: null,
    OnboardingColumn: 'Electric',
    ResidentOnboardingStatusName: 'Review Pending',
    ServiceProfileID: 56,
    CountBedroom: null,
    CountBathroom: null,
    SquareFootage: null,
    DaysToLeaseStart: 7,
    Previous_Address: null,
    Previous_Secondary: null,
    Previous_City: null,
    Previous_State: null,
    Previous_Zip: null,
    IsResidentOnboarding: 1,
    DateLastUpdated: '2023-01-20T17:17:07.363',
    ExportFileName: 'TIN6581319_ElectricPOS.pdf',
    data: {
      Electric: {
        ResidentOnboardingStatusID: 2,
        ResidentOnboardingStatusName: 'Review Pending',
        Reason: '',
        ProviderServiceList: [
          {
            PropertyID: 166313,
            PropertyName: 'Camelback Square',
            UnitNumber: '1101',
            Address: '1714 21st St',
            Secondary: 'Apt 1101',
            City: 'Sacramento',
            State: 'CA',
            Zip: '95811',
            ResidentID: 879,
            UnitID: 13770,
            TenantId: 'TIN6581319',
            ResidentStatusID: 1,
            ResidentStatusName: 'Future',
            ResidentRelationshipID: 1,
            ResidentRelationshipName: 'Head of Household',
            FirstName: 'Isabella',
            LastName: 'Valencia',
            Email: 'conference+42358@movingin.com',
            Phone: '602-791-8802',
            DateLeaseStart: '2023-01-27T00:00:00',
            DateLeaseRenewal: '2023-10-26T00:00:00',
            Created: '2022-10-20T22:52:15.287',
            CreatedBy: 'kurt@movingin.com',
            Updated: null,
            UpdatedBy: null,
            InvitationID: null,
            InvitationLink: null,
            HasInvitation: false,
            DateInvitationCreated: null,
            DateInvitationDelivered: null,
            DateInvitationRead: null,
            DateInvitationClicked: null,
            InvitationUrlClicked: null,
            DateInvitationDropped: null,
            InvitationDropReason: null,
            DateInvitationVisited: null,
            ServiceAddressID: 8390551,
            DisplayOrder: 1,
            Grouping: 'provider-service',
            Description: 'Electric Service',
            ResidentOnboardingStatusID: 2,
            Reason: '',
            ProviderServiceID: 9,
            ResidentVehicleID: null,
            ResidentPetID: null,
            OnboardingColumn: 'Electric',
            ResidentOnboardingStatusName: 'Review Pending',
            ServiceProfileID: 56,
            CountBedroom: null,
            CountBathroom: null,
            SquareFootage: null,
            DaysToLeaseStart: 7,
            Previous_Address: null,
            Previous_Secondary: null,
            Previous_City: null,
            Previous_State: null,
            Previous_Zip: null,
            IsResidentOnboarding: 1,
            DateLastUpdated: '2023-01-20T17:17:07.363',
            ExportFileName: 'TIN6581319_ElectricPOS.pdf'
          }
        ],
        DateLastUpdated: '2023-01-20T17:17:07.363',
        ExportFileName: 'TIN6581319_ElectricPOS.pdf'
      },
      'Natural Gas': {
        ResidentOnboardingStatusID: 2,
        ResidentOnboardingStatusName: 'Review Pending',
        Reason: '',
        ProviderServiceList: [
          {
            PropertyID: 166313,
            PropertyName: 'Camelback Square',
            UnitNumber: '1101',
            Address: '1714 21st St',
            Secondary: 'Apt 1101',
            City: 'Sacramento',
            State: 'CA',
            Zip: '95811',
            ResidentID: 879,
            UnitID: 13770,
            TenantId: 'TIN6581319',
            ResidentStatusID: 1,
            ResidentStatusName: 'Future',
            ResidentRelationshipID: 1,
            ResidentRelationshipName: 'Head of Household',
            FirstName: 'Isabella',
            LastName: 'Valencia',
            Email: 'conference+42358@movingin.com',
            Phone: '602-791-8802',
            DateLeaseStart: '2023-01-27T00:00:00',
            DateLeaseRenewal: '2023-10-26T00:00:00',
            Created: '2022-10-20T22:52:15.287',
            CreatedBy: 'kurt@movingin.com',
            Updated: null,
            UpdatedBy: null,
            InvitationID: null,
            InvitationLink: null,
            HasInvitation: false,
            DateInvitationCreated: null,
            DateInvitationDelivered: null,
            DateInvitationRead: null,
            DateInvitationClicked: null,
            InvitationUrlClicked: null,
            DateInvitationDropped: null,
            InvitationDropReason: null,
            DateInvitationVisited: null,
            ServiceAddressID: 8390551,
            DisplayOrder: 2,
            Grouping: 'provider-service',
            Description: 'Natural Gas Service',
            ResidentOnboardingStatusID: 2,
            Reason: '',
            ProviderServiceID: 8,
            ResidentVehicleID: null,
            ResidentPetID: null,
            OnboardingColumn: 'Natural Gas',
            ResidentOnboardingStatusName: 'Review Pending',
            ServiceProfileID: 56,
            CountBedroom: null,
            CountBathroom: null,
            SquareFootage: null,
            DaysToLeaseStart: 7,
            Previous_Address: null,
            Previous_Secondary: null,
            Previous_City: null,
            Previous_State: null,
            Previous_Zip: null,
            IsResidentOnboarding: 1,
            DateLastUpdated: '2023-01-20T17:19:52.447',
            ExportFileName: 'TIN6581319_NaturalGasPOS.pdf'
          }
        ],
        DateLastUpdated: '2023-01-20T17:19:52.447',
        ExportFileName: 'TIN6581319_NaturalGasPOS.pdf'
      },
      'Renters Insurance': {
        ResidentOnboardingStatusID: 2,
        ResidentOnboardingStatusName: 'Review Pending',
        Reason: '',
        ProviderServiceList: [
          {
            PropertyID: 166313,
            PropertyName: 'Camelback Square',
            UnitNumber: '1101',
            Address: '1714 21st St',
            Secondary: 'Apt 1101',
            City: 'Sacramento',
            State: 'CA',
            Zip: '95811',
            ResidentID: 879,
            UnitID: 13770,
            TenantId: 'TIN6581319',
            ResidentStatusID: 1,
            ResidentStatusName: 'Future',
            ResidentRelationshipID: 1,
            ResidentRelationshipName: 'Head of Household',
            FirstName: 'Isabella',
            LastName: 'Valencia',
            Email: 'conference+42358@movingin.com',
            Phone: '602-791-8802',
            DateLeaseStart: '2023-01-27T00:00:00',
            DateLeaseRenewal: '2023-10-26T00:00:00',
            Created: '2022-10-20T22:52:15.287',
            CreatedBy: 'kurt@movingin.com',
            Updated: null,
            UpdatedBy: null,
            InvitationID: null,
            InvitationLink: null,
            HasInvitation: false,
            DateInvitationCreated: null,
            DateInvitationDelivered: null,
            DateInvitationRead: null,
            DateInvitationClicked: null,
            InvitationUrlClicked: null,
            DateInvitationDropped: null,
            InvitationDropReason: null,
            DateInvitationVisited: null,
            ServiceAddressID: 8390551,
            DisplayOrder: 3,
            Grouping: 'provider-service',
            Description: 'Renters Insurance',
            ResidentOnboardingStatusID: 2,
            Reason: '',
            ProviderServiceID: 37,
            ResidentVehicleID: null,
            ResidentPetID: null,
            OnboardingColumn: 'Renters Insurance',
            ResidentOnboardingStatusName: 'Review Pending',
            ServiceProfileID: 56,
            CountBedroom: null,
            CountBathroom: null,
            SquareFootage: null,
            DaysToLeaseStart: 7,
            Previous_Address: null,
            Previous_Secondary: null,
            Previous_City: null,
            Previous_State: null,
            Previous_Zip: null,
            IsResidentOnboarding: 1,
            DateLastUpdated: '2023-01-20T14:41:31.067',
            ExportFileName: 'TIN6581319_RentersInsurancePOS.pdf'
          }
        ],
        DateLastUpdated: '2023-01-20T14:41:31.067',
        ExportFileName: 'TIN6581319_RentersInsurancePOS.pdf'
      },
      'Vehicle Registration': {
        ResidentOnboardingStatusID: 2,
        ResidentOnboardingStatusName: 'Review Pending',
        Reason: '',
        ProviderServiceList: [],
        DateLastUpdated: '2023-01-20T17:23:06.973',
        ExportFileName: 'TIN6581319_VehicleReg.pdf',
        Grouping: 'vehicle'
      },
      'Pet Registration': {
        ResidentOnboardingStatusID: 3,
        ResidentOnboardingStatusName: 'Completed',
        Reason: '',
        ProviderServiceList: [],
        DateLastUpdated: '2023-01-20T16:33:21.567',
        ExportFileName: 'TIN6581319_PetReg.pdf',
        Grouping: 'pet'
      },
      'Move-in Condition Form': {
        ResidentOnboardingStatusID: 0,
        ResidentOnboardingStatusName: 'Available 01/27/23',
        Reason: '',
        ProviderServiceList: [],
        DateLastUpdated: null,
        ExportFileName: null,
        Grouping: 'walkthrough'
      }
    }
  },
  {
    PropertyID: 166313,
    PropertyName: 'Camelback Square',
    UnitNumber: '2116',
    Address: '1714 21st St',
    Secondary: 'Apt 2116',
    City: 'Sacramento',
    State: 'CA',
    Zip: '95811',
    ResidentID: 850,
    UnitID: 13953,
    TenantId: 'TIN6364188',
    ResidentStatusID: 1,
    ResidentStatusName: 'Future',
    ResidentRelationshipID: 1,
    ResidentRelationshipName: 'Head of Household',
    FirstName: 'Ava',
    LastName: 'Hansen',
    Email: 'conference+42253@movingin.com',
    Phone: '602-791-8802',
    DateLeaseStart: '2023-01-28T00:00:00',
    DateLeaseRenewal: '2024-04-28T00:00:00',
    Created: '2022-10-20T22:52:15.287',
    CreatedBy: 'kurt@movingin.com',
    Updated: null,
    UpdatedBy: null,
    InvitationID: null,
    InvitationLink: null,
    HasInvitation: false,
    DateInvitationCreated: null,
    DateInvitationDelivered: null,
    DateInvitationRead: null,
    DateInvitationClicked: null,
    InvitationUrlClicked: null,
    DateInvitationDropped: null,
    InvitationDropReason: null,
    DateInvitationVisited: null,
    ServiceAddressID: 8390734,
    DisplayOrder: 1,
    Grouping: 'provider-service',
    Description: 'Electric Service',
    ResidentOnboardingStatusID: 1,
    Reason:
      'Provider name required,Provider account number required,Proof of service document upload required,Acknowledgment checkbox required,',
    ProviderServiceID: 9,
    ResidentVehicleID: null,
    ResidentPetID: null,
    OnboardingColumn: 'Electric',
    ResidentOnboardingStatusName: 'Action Required',
    ServiceProfileID: 56,
    CountBedroom: null,
    CountBathroom: null,
    SquareFootage: null,
    DaysToLeaseStart: 8,
    Previous_Address: null,
    Previous_Secondary: null,
    Previous_City: null,
    Previous_State: null,
    Previous_Zip: null,
    IsResidentOnboarding: 1,
    DateLastUpdated: '2023-01-20T14:54:44.327',
    ExportFileName: 'TIN6364188_ElectricPOS.pdf',
    data: {
      Electric: {
        ResidentOnboardingStatusID: 1,
        ResidentOnboardingStatusName: 'Action Required',
        Reason:
          'Electric Service,&nbsp;&nbsp;&nbsp;&bull; Provider name required,&nbsp;&nbsp;&nbsp;&bull; Provider account number required,&nbsp;&nbsp;&nbsp;&bull; Proof of service document upload required,&nbsp;&nbsp;&nbsp;&bull; Acknowledgment checkbox required',
        ProviderServiceList: [
          {
            PropertyID: 166313,
            PropertyName: 'Camelback Square',
            UnitNumber: '2116',
            Address: '1714 21st St',
            Secondary: 'Apt 2116',
            City: 'Sacramento',
            State: 'CA',
            Zip: '95811',
            ResidentID: 850,
            UnitID: 13953,
            TenantId: 'TIN6364188',
            ResidentStatusID: 1,
            ResidentStatusName: 'Future',
            ResidentRelationshipID: 1,
            ResidentRelationshipName: 'Head of Household',
            FirstName: 'Ava',
            LastName: 'Hansen',
            Email: 'conference+42253@movingin.com',
            Phone: '602-791-8802',
            DateLeaseStart: '2023-01-28T00:00:00',
            DateLeaseRenewal: '2024-04-28T00:00:00',
            Created: '2022-10-20T22:52:15.287',
            CreatedBy: 'kurt@movingin.com',
            Updated: null,
            UpdatedBy: null,
            InvitationID: null,
            InvitationLink: null,
            HasInvitation: false,
            DateInvitationCreated: null,
            DateInvitationDelivered: null,
            DateInvitationRead: null,
            DateInvitationClicked: null,
            InvitationUrlClicked: null,
            DateInvitationDropped: null,
            InvitationDropReason: null,
            DateInvitationVisited: null,
            ServiceAddressID: 8390734,
            DisplayOrder: 1,
            Grouping: 'provider-service',
            Description: 'Electric Service',
            ResidentOnboardingStatusID: 1,
            Reason:
              'Provider name required,Provider account number required,Proof of service document upload required,Acknowledgment checkbox required,',
            ProviderServiceID: 9,
            ResidentVehicleID: null,
            ResidentPetID: null,
            OnboardingColumn: 'Electric',
            ResidentOnboardingStatusName: 'Action Required',
            ServiceProfileID: 56,
            CountBedroom: null,
            CountBathroom: null,
            SquareFootage: null,
            DaysToLeaseStart: 8,
            Previous_Address: null,
            Previous_Secondary: null,
            Previous_City: null,
            Previous_State: null,
            Previous_Zip: null,
            IsResidentOnboarding: 1,
            DateLastUpdated: '2023-01-20T14:54:44.327',
            ExportFileName: 'TIN6364188_ElectricPOS.pdf'
          }
        ],
        DateLastUpdated: '2023-01-20T14:54:44.327',
        ExportFileName: 'TIN6364188_ElectricPOS.pdf'
      },
      'Natural Gas': {
        ResidentOnboardingStatusID: 1,
        ResidentOnboardingStatusName: 'Action Required',
        Reason:
          'Natural Gas Service,&nbsp;&nbsp;&nbsp;&bull; Provider name required,&nbsp;&nbsp;&nbsp;&bull; Provider account number required,&nbsp;&nbsp;&nbsp;&bull; Proof of service document upload required,&nbsp;&nbsp;&nbsp;&bull; Acknowledgment checkbox required',
        ProviderServiceList: [
          {
            PropertyID: 166313,
            PropertyName: 'Camelback Square',
            UnitNumber: '2116',
            Address: '1714 21st St',
            Secondary: 'Apt 2116',
            City: 'Sacramento',
            State: 'CA',
            Zip: '95811',
            ResidentID: 850,
            UnitID: 13953,
            TenantId: 'TIN6364188',
            ResidentStatusID: 1,
            ResidentStatusName: 'Future',
            ResidentRelationshipID: 1,
            ResidentRelationshipName: 'Head of Household',
            FirstName: 'Ava',
            LastName: 'Hansen',
            Email: 'conference+42253@movingin.com',
            Phone: '602-791-8802',
            DateLeaseStart: '2023-01-28T00:00:00',
            DateLeaseRenewal: '2024-04-28T00:00:00',
            Created: '2022-10-20T22:52:15.287',
            CreatedBy: 'kurt@movingin.com',
            Updated: null,
            UpdatedBy: null,
            InvitationID: null,
            InvitationLink: null,
            HasInvitation: false,
            DateInvitationCreated: null,
            DateInvitationDelivered: null,
            DateInvitationRead: null,
            DateInvitationClicked: null,
            InvitationUrlClicked: null,
            DateInvitationDropped: null,
            InvitationDropReason: null,
            DateInvitationVisited: null,
            ServiceAddressID: 8390734,
            DisplayOrder: 2,
            Grouping: 'provider-service',
            Description: 'Natural Gas Service',
            ResidentOnboardingStatusID: 1,
            Reason:
              'Provider name required,Provider account number required,Proof of service document upload required,Acknowledgment checkbox required,',
            ProviderServiceID: 8,
            ResidentVehicleID: null,
            ResidentPetID: null,
            OnboardingColumn: 'Natural Gas',
            ResidentOnboardingStatusName: 'Action Required',
            ServiceProfileID: 56,
            CountBedroom: null,
            CountBathroom: null,
            SquareFootage: null,
            DaysToLeaseStart: 8,
            Previous_Address: null,
            Previous_Secondary: null,
            Previous_City: null,
            Previous_State: null,
            Previous_Zip: null,
            IsResidentOnboarding: 1,
            DateLastUpdated: '2023-01-20T14:54:58.36',
            ExportFileName: 'TIN6364188_NaturalGasPOS.pdf'
          }
        ],
        DateLastUpdated: '2023-01-20T14:54:58.36',
        ExportFileName: 'TIN6364188_NaturalGasPOS.pdf'
      },
      'Renters Insurance': {
        ResidentOnboardingStatusID: 1,
        ResidentOnboardingStatusName: 'Action Required',
        Reason:
          'Renters Insurance,&nbsp;&nbsp;&nbsp;&bull; Insurance company name required,&nbsp;&nbsp;&nbsp;&bull; Policy number required,&nbsp;&nbsp;&nbsp;&bull; Policy start date required,&nbsp;&nbsp;&nbsp;&bull; Policy end date required,&nbsp;&nbsp;&nbsp;&bull; Proof of Coverage document upload required,&nbsp;&nbsp;&nbsp;&bull; Acknowledgment checkbox required',
        ProviderServiceList: [
          {
            PropertyID: 166313,
            PropertyName: 'Camelback Square',
            UnitNumber: '2116',
            Address: '1714 21st St',
            Secondary: 'Apt 2116',
            City: 'Sacramento',
            State: 'CA',
            Zip: '95811',
            ResidentID: 850,
            UnitID: 13953,
            TenantId: 'TIN6364188',
            ResidentStatusID: 1,
            ResidentStatusName: 'Future',
            ResidentRelationshipID: 1,
            ResidentRelationshipName: 'Head of Household',
            FirstName: 'Ava',
            LastName: 'Hansen',
            Email: 'conference+42253@movingin.com',
            Phone: '602-791-8802',
            DateLeaseStart: '2023-01-28T00:00:00',
            DateLeaseRenewal: '2024-04-28T00:00:00',
            Created: '2022-10-20T22:52:15.287',
            CreatedBy: 'kurt@movingin.com',
            Updated: null,
            UpdatedBy: null,
            InvitationID: null,
            InvitationLink: null,
            HasInvitation: false,
            DateInvitationCreated: null,
            DateInvitationDelivered: null,
            DateInvitationRead: null,
            DateInvitationClicked: null,
            InvitationUrlClicked: null,
            DateInvitationDropped: null,
            InvitationDropReason: null,
            DateInvitationVisited: null,
            ServiceAddressID: 8390734,
            DisplayOrder: 3,
            Grouping: 'provider-service',
            Description: 'Renters Insurance',
            ResidentOnboardingStatusID: 1,
            Reason:
              'Insurance company name required,Policy number required,Policy start date required,Policy end date required,Proof of Coverage document upload required,Acknowledgment checkbox required,',
            ProviderServiceID: 37,
            ResidentVehicleID: null,
            ResidentPetID: null,
            OnboardingColumn: 'Renters Insurance',
            ResidentOnboardingStatusName: 'Action Required',
            ServiceProfileID: 56,
            CountBedroom: null,
            CountBathroom: null,
            SquareFootage: null,
            DaysToLeaseStart: 8,
            Previous_Address: null,
            Previous_Secondary: null,
            Previous_City: null,
            Previous_State: null,
            Previous_Zip: null,
            IsResidentOnboarding: 1,
            DateLastUpdated: null,
            ExportFileName: 'TIN6364188_RentersInsurancePOS.pdf'
          }
        ],
        DateLastUpdated: null,
        ExportFileName: 'TIN6364188_RentersInsurancePOS.pdf'
      },
      'Vehicle Registration': {
        ResidentOnboardingStatusID: 1,
        ResidentOnboardingStatusName: 'Action Required',
        Reason:
          'Vehicle Registration - Vehicle #1,&nbsp;&nbsp;&nbsp;&bull; Vehicle year required,&nbsp;&nbsp;&nbsp;&bull; Vehicle make required,&nbsp;&nbsp;&nbsp;&bull; Vehicle model required,&nbsp;&nbsp;&nbsp;&bull; Vehicle color required,&nbsp;&nbsp;&nbsp;&bull; Vehicle license plate number required,&nbsp;&nbsp;&nbsp;&bull; Vehicle license plate state required,&nbsp;&nbsp;&nbsp;&bull; Front vehicle photo required,&nbsp;&nbsp;&nbsp;&bull; Back vehicle photo required,&nbsp;&nbsp;&nbsp;&bull; Side vehicle photo required,&nbsp;&nbsp;&nbsp;&bull; Angled vehicle photo required,&nbsp;&nbsp;&nbsp;&bull; Acknowledgment checkbox required',
        ProviderServiceList: [],
        DateLastUpdated: '2023-01-20T19:14:41.013',
        ExportFileName: 'TIN6364188_VehicleReg.pdf',
        Grouping: 'vehicle'
      },
      'Pet Registration': {
        ResidentOnboardingStatusID: 1,
        ResidentOnboardingStatusName: 'Action Required',
        Reason: 'Pet Registration,&nbsp;&nbsp;&nbsp;&bull; Add pet information',
        ProviderServiceList: [],
        DateLastUpdated: null,
        ExportFileName: null,
        Grouping: 'pet'
      },
      'Move-in Condition Form': {
        ResidentOnboardingStatusID: 0,
        ResidentOnboardingStatusName: 'Available 01/28/23',
        Reason: '',
        ProviderServiceList: [],
        DateLastUpdated: null,
        ExportFileName: null,
        Grouping: 'walkthrough'
      }
    }
  },
  {
    PropertyID: 166313,
    PropertyName: 'Camelback Square',
    UnitNumber: '2071',
    Address: '1714 21st St',
    Secondary: 'Apt 2071',
    City: 'Sacramento',
    State: 'CA',
    Zip: '95811',
    ResidentID: 1113,
    UnitID: 13908,
    TenantId: 'TIN8333343',
    ResidentStatusID: 1,
    ResidentStatusName: 'Future',
    ResidentRelationshipID: 1,
    ResidentRelationshipName: 'Head of Household',
    FirstName: 'Flor',
    LastName: 'Sexton',
    Email: 'conference+32164@movingin.com',
    Phone: '602-791-8802',
    DateLeaseStart: '2023-01-29T00:00:00',
    DateLeaseRenewal: '2024-04-29T00:00:00',
    Created: '2022-10-20T22:52:15.287',
    CreatedBy: 'kurt@movingin.com',
    Updated: null,
    UpdatedBy: null,
    InvitationID: null,
    InvitationLink: null,
    HasInvitation: false,
    DateInvitationCreated: null,
    DateInvitationDelivered: null,
    DateInvitationRead: null,
    DateInvitationClicked: null,
    InvitationUrlClicked: null,
    DateInvitationDropped: null,
    InvitationDropReason: null,
    DateInvitationVisited: null,
    ServiceAddressID: 8390689,
    DisplayOrder: 1,
    Grouping: 'provider-service',
    Description: 'Electric Service',
    ResidentOnboardingStatusID: 2,
    Reason: '',
    ProviderServiceID: 9,
    ResidentVehicleID: null,
    ResidentPetID: null,
    OnboardingColumn: 'Electric',
    ResidentOnboardingStatusName: 'Review Pending',
    ServiceProfileID: 56,
    CountBedroom: null,
    CountBathroom: null,
    SquareFootage: null,
    DaysToLeaseStart: 9,
    Previous_Address: null,
    Previous_Secondary: null,
    Previous_City: null,
    Previous_State: null,
    Previous_Zip: null,
    IsResidentOnboarding: 1,
    DateLastUpdated: '2023-01-21T00:18:32.86',
    ExportFileName: 'TIN8333343_ElectricPOS.pdf',
    data: {
      Electric: {
        ResidentOnboardingStatusID: 2,
        ResidentOnboardingStatusName: 'Review Pending',
        Reason: '',
        ProviderServiceList: [
          {
            PropertyID: 166313,
            PropertyName: 'Camelback Square',
            UnitNumber: '2071',
            Address: '1714 21st St',
            Secondary: 'Apt 2071',
            City: 'Sacramento',
            State: 'CA',
            Zip: '95811',
            ResidentID: 1113,
            UnitID: 13908,
            TenantId: 'TIN8333343',
            ResidentStatusID: 1,
            ResidentStatusName: 'Future',
            ResidentRelationshipID: 1,
            ResidentRelationshipName: 'Head of Household',
            FirstName: 'Flor',
            LastName: 'Sexton',
            Email: 'conference+32164@movingin.com',
            Phone: '602-791-8802',
            DateLeaseStart: '2023-01-29T00:00:00',
            DateLeaseRenewal: '2024-04-29T00:00:00',
            Created: '2022-10-20T22:52:15.287',
            CreatedBy: 'kurt@movingin.com',
            Updated: null,
            UpdatedBy: null,
            InvitationID: null,
            InvitationLink: null,
            HasInvitation: false,
            DateInvitationCreated: null,
            DateInvitationDelivered: null,
            DateInvitationRead: null,
            DateInvitationClicked: null,
            InvitationUrlClicked: null,
            DateInvitationDropped: null,
            InvitationDropReason: null,
            DateInvitationVisited: null,
            ServiceAddressID: 8390689,
            DisplayOrder: 1,
            Grouping: 'provider-service',
            Description: 'Electric Service',
            ResidentOnboardingStatusID: 2,
            Reason: '',
            ProviderServiceID: 9,
            ResidentVehicleID: null,
            ResidentPetID: null,
            OnboardingColumn: 'Electric',
            ResidentOnboardingStatusName: 'Review Pending',
            ServiceProfileID: 56,
            CountBedroom: null,
            CountBathroom: null,
            SquareFootage: null,
            DaysToLeaseStart: 9,
            Previous_Address: null,
            Previous_Secondary: null,
            Previous_City: null,
            Previous_State: null,
            Previous_Zip: null,
            IsResidentOnboarding: 1,
            DateLastUpdated: '2023-01-21T00:18:32.86',
            ExportFileName: 'TIN8333343_ElectricPOS.pdf'
          }
        ],
        DateLastUpdated: '2023-01-21T00:18:32.86',
        ExportFileName: 'TIN8333343_ElectricPOS.pdf'
      },
      'Natural Gas': {
        ResidentOnboardingStatusID: 2,
        ResidentOnboardingStatusName: 'Review Pending',
        Reason: '',
        ProviderServiceList: [
          {
            PropertyID: 166313,
            PropertyName: 'Camelback Square',
            UnitNumber: '2071',
            Address: '1714 21st St',
            Secondary: 'Apt 2071',
            City: 'Sacramento',
            State: 'CA',
            Zip: '95811',
            ResidentID: 1113,
            UnitID: 13908,
            TenantId: 'TIN8333343',
            ResidentStatusID: 1,
            ResidentStatusName: 'Future',
            ResidentRelationshipID: 1,
            ResidentRelationshipName: 'Head of Household',
            FirstName: 'Flor',
            LastName: 'Sexton',
            Email: 'conference+32164@movingin.com',
            Phone: '602-791-8802',
            DateLeaseStart: '2023-01-29T00:00:00',
            DateLeaseRenewal: '2024-04-29T00:00:00',
            Created: '2022-10-20T22:52:15.287',
            CreatedBy: 'kurt@movingin.com',
            Updated: null,
            UpdatedBy: null,
            InvitationID: null,
            InvitationLink: null,
            HasInvitation: false,
            DateInvitationCreated: null,
            DateInvitationDelivered: null,
            DateInvitationRead: null,
            DateInvitationClicked: null,
            InvitationUrlClicked: null,
            DateInvitationDropped: null,
            InvitationDropReason: null,
            DateInvitationVisited: null,
            ServiceAddressID: 8390689,
            DisplayOrder: 2,
            Grouping: 'provider-service',
            Description: 'Natural Gas Service',
            ResidentOnboardingStatusID: 2,
            Reason: '',
            ProviderServiceID: 8,
            ResidentVehicleID: null,
            ResidentPetID: null,
            OnboardingColumn: 'Natural Gas',
            ResidentOnboardingStatusName: 'Review Pending',
            ServiceProfileID: 56,
            CountBedroom: null,
            CountBathroom: null,
            SquareFootage: null,
            DaysToLeaseStart: 9,
            Previous_Address: null,
            Previous_Secondary: null,
            Previous_City: null,
            Previous_State: null,
            Previous_Zip: null,
            IsResidentOnboarding: 1,
            DateLastUpdated: '2023-01-21T00:18:22.76',
            ExportFileName: 'TIN8333343_NaturalGasPOS.pdf'
          }
        ],
        DateLastUpdated: '2023-01-21T00:18:22.76',
        ExportFileName: 'TIN8333343_NaturalGasPOS.pdf'
      },
      'Renters Insurance': {
        ResidentOnboardingStatusID: 2,
        ResidentOnboardingStatusName: 'Review Pending',
        Reason: '',
        ProviderServiceList: [
          {
            PropertyID: 166313,
            PropertyName: 'Camelback Square',
            UnitNumber: '2071',
            Address: '1714 21st St',
            Secondary: 'Apt 2071',
            City: 'Sacramento',
            State: 'CA',
            Zip: '95811',
            ResidentID: 1113,
            UnitID: 13908,
            TenantId: 'TIN8333343',
            ResidentStatusID: 1,
            ResidentStatusName: 'Future',
            ResidentRelationshipID: 1,
            ResidentRelationshipName: 'Head of Household',
            FirstName: 'Flor',
            LastName: 'Sexton',
            Email: 'conference+32164@movingin.com',
            Phone: '602-791-8802',
            DateLeaseStart: '2023-01-29T00:00:00',
            DateLeaseRenewal: '2024-04-29T00:00:00',
            Created: '2022-10-20T22:52:15.287',
            CreatedBy: 'kurt@movingin.com',
            Updated: null,
            UpdatedBy: null,
            InvitationID: null,
            InvitationLink: null,
            HasInvitation: false,
            DateInvitationCreated: null,
            DateInvitationDelivered: null,
            DateInvitationRead: null,
            DateInvitationClicked: null,
            InvitationUrlClicked: null,
            DateInvitationDropped: null,
            InvitationDropReason: null,
            DateInvitationVisited: null,
            ServiceAddressID: 8390689,
            DisplayOrder: 3,
            Grouping: 'provider-service',
            Description: 'Renters Insurance',
            ResidentOnboardingStatusID: 2,
            Reason: '',
            ProviderServiceID: 37,
            ResidentVehicleID: null,
            ResidentPetID: null,
            OnboardingColumn: 'Renters Insurance',
            ResidentOnboardingStatusName: 'Review Pending',
            ServiceProfileID: 56,
            CountBedroom: null,
            CountBathroom: null,
            SquareFootage: null,
            DaysToLeaseStart: 9,
            Previous_Address: null,
            Previous_Secondary: null,
            Previous_City: null,
            Previous_State: null,
            Previous_Zip: null,
            IsResidentOnboarding: 1,
            DateLastUpdated: '2023-01-20T20:19:42.093',
            ExportFileName: 'TIN8333343_RentersInsurancePOS.pdf'
          }
        ],
        DateLastUpdated: '2023-01-20T20:19:42.093',
        ExportFileName: 'TIN8333343_RentersInsurancePOS.pdf'
      },
      'Vehicle Registration': {
        ResidentOnboardingStatusID: 1,
        ResidentOnboardingStatusName: 'Action Required',
        Reason:
          'Vehicle Registration,&nbsp;&nbsp;&nbsp;&bull; Add vehicle information',
        ProviderServiceList: [],
        DateLastUpdated: null,
        ExportFileName: null,
        Grouping: 'vehicle'
      },
      'Pet Registration': {
        ResidentOnboardingStatusID: 1,
        ResidentOnboardingStatusName: 'Action Required',
        Reason: 'Pet Registration,&nbsp;&nbsp;&nbsp;&bull; Add pet information',
        ProviderServiceList: [],
        DateLastUpdated: null,
        ExportFileName: null,
        Grouping: 'pet'
      },
      'Move-in Condition Form': {
        ResidentOnboardingStatusID: 0,
        ResidentOnboardingStatusName: 'Available 01/29/23',
        Reason: '',
        ProviderServiceList: [],
        DateLastUpdated: null,
        ExportFileName: null,
        Grouping: 'walkthrough'
      }
    }
  }
];

export const defaultResidentOnboardingChecklist = [
  {
    DisplayOrder: 1,
    Grouping: 'provider-service',
    Description: 'Electric Service',
    ResidentOnboardingStatusID: 3,
    Reason: '',
    ResidentOnboardingStatusName: 'Completed',
    ProviderServiceID: 9,
    ResidentVehicleID: null,
    ResidentPetID: null,
    OnboardingColumn: 'Electric',
    VehicleRegistrationInclude: true,
    VehicleRegistrationIncludeYear: true,
    VehicleRegistrationIncludeMake: true,
    VehicleRegistrationIncludeModel: true,
    VehicleRegistrationIncludeColor: true,
    VehicleRegistrationIncludeLicensePlateNumber: true,
    VehicleRegistrationIncludeLicensePlateState: true,
    VehicleRegistrationIncludeVIN: null,
    VehicleRegistrationPhotoIncludeAny: true,
    VehicleRegistrationPhotoIncludeFront: true,
    VehicleRegistrationPhotoIncludeBack: true,
    VehicleRegistrationPhotoIncludeSide: true,
    VehicleRegistrationIncludeProofOfInsurance: null,
    VehicleRegistrationIncludeCheckbox: true,
    VehicleRegistrationCheckboxText:
      'I agree to maintain liability and comprehensive insurance coverage on any vehicle used, parked or stored on site for the duration of my lease.',
    PetInclude: true,
    PetIncludeType: true,
    PetIncludeName: true,
    PetIncludeAge: true,
    PetIncludeGender: true,
    PetIncludeBreed: true,
    PetIncludeWeight: true,
    PetIncludeColor: true,
    PetIncludeProofOfVaccinations: true,
    PetCheckboxInclude1: null,
    PetCheckboxText1: null,
    PetCheckboxInclude2: null,
    PetCheckboxText2: null,
    PetCheckboxInclude3: null,
    PetCheckboxText3: null,
    PetPhotoIncludeAny: true,
    PetPhotoIncludeFace: true,
    PetPhotoIncludeSitting: true,
    PetPhotoIncludeStanding: true,
    PetInterviewRequired: true,
    PetInterviewSendReminder: null,
    PetInterviewText:
      'All dogs must be brought in to the leasing office for a pet interview before you move in. Please contact the leasing office to schedule a time to bring them in.',
    PetScreeningRequired: null,
    PetScreeningText: null,
    PetPolicyIncludeCheckbox: true,
    PetPolicyText:
      'I understand that this community has a strict pet policy in place as outlined in my lease and I am confirming that myself and my pet(s) will remain in full compliance with that policy through out the term of my lease.',
    PetLiabilityIncludeCheckbox: true,
    PetLiabilityText:
      'I accept and acknowledge personal responsibility and liability for any injury or damage caused as a result of the wrongful or aggressive behavior of my dog, whether the loss occurs as a result of biting, jumping, scratching, digging, charging or any other behavior.',
    ElevatorReservationInclude: false,
    ElevatorRegistrationText:
      'You must schedule your reservation prior to your move. Please select the three different 2-hour windows that work best for you based on desirability, with the first choice being the most desirable, and hit Submit. Someone with the property will then get back to you to confirm your reservation.',
    LoadingDockRegistrationInclude: null,
    LoadingDockRegistrationText: null,
    TemporaryParkingPermitInclude: null,
    TemporaryParkingPermitText: null,
    ResidentOnboardingOther1: null,
    ResidentOnboardingOther2: null,
    ResidentOnboardingOther3: null,
    IsServiceAvailable: null,
    IsDefaultProviderCorrect: true,
    IsServiceRequired: true,
    IsServiceIncludedInRent: null,
    IsServiceProofRequired: true,
    IsServiceProofDeliver: false,
    IsServiceProofUpload: true,
    IsServiceProofUseUrl: false,
    IsServicePaidToThirdParty: null,
    IsServicePaidWithRent: null,
    IsServicePaidToOther: null,
    IsServiceOfferedOffsiteLink: null,
    ServiceProofIncludeProviderName: true,
    ServiceProofIncludeAccountNumber: true,
    ServiceProofIncludeStartDate: true,
    ServiceProofIncludeEndDate: null,
    ServiceProofIncludeVeificationOfService: null,
    ServiceProofIncludeDocUpload: true,
    ServiceProofAlt1: null,
    ServiceProofAlt2: null,
    ServiceProofAlt3: null,
    ServiceProofAlt4: null,
    ServiceProofAlt5: null,
    ServiceProofUrl: null,
    OffisteLinkUrl: null,
    CustomMessage: null,
    ProviderNameEntered: null,
    ServiceAddendumFile: null,
    HasPreferredProvider: null,
    InsuranceIsInterestedPartyRequired: null,
    InsuranceIsMinCoverageRequired: null,
    InsuranceMinCoverageLiabilityRequired: null,
    InsuranceMinCoverageLiabilityValue: null,
    InsuranceMinCoveragePropertyRequired: null,
    InsuranceMinCoveragePropertyValue: null,
    InsuranceMinCoverageOtherRequired: null,
    InsuranceMinCoverageOtherLabel: null,
    InsuranceMinCoverageOtherValue: null,
    InsuranceInterestedPartyText: null,
    InternetHasInHousePackage: null,
    InternetIsAllowSatellite: null,
    StorageHasOnsite: null,
    StorageHasGarages: null,
    StorageHasParking: null,
    StorageIsInSoftware: null,
    StorageShowOnsite: null,
    InternetIsPartOfRent: null,
    InternetIsMarketingAgreement: null,
    InternetIsSpecialPricing: null,
    InternetSpecialPricingUrl: null,
    IsPreferredProviderExclusive: null,
    CheckboxInclude: true,
    CheckboxText:
      'I understand that as part of my lease I agreed to maintain Electric Service in my name for the duration of my lease. I further understand and agree that if I fail to establish or maintain service in my name that the property may bill me for any charges they pay on my behalf, plus a reasonable administrative fee.',
    ResidentOnboardingStatusNote: null,
    ResidentWalkthroughID: null
  },
  {
    DisplayOrder: 2,
    Grouping: 'provider-service',
    Description: 'Natural Gas Service',
    ResidentOnboardingStatusID: 3,
    Reason: '',
    ResidentOnboardingStatusName: 'Completed',
    ProviderServiceID: 8,
    ResidentVehicleID: null,
    ResidentPetID: null,
    OnboardingColumn: 'Natural Gas',
    VehicleRegistrationInclude: true,
    VehicleRegistrationIncludeYear: true,
    VehicleRegistrationIncludeMake: true,
    VehicleRegistrationIncludeModel: true,
    VehicleRegistrationIncludeColor: true,
    VehicleRegistrationIncludeLicensePlateNumber: true,
    VehicleRegistrationIncludeLicensePlateState: true,
    VehicleRegistrationIncludeVIN: null,
    VehicleRegistrationPhotoIncludeAny: true,
    VehicleRegistrationPhotoIncludeFront: true,
    VehicleRegistrationPhotoIncludeBack: true,
    VehicleRegistrationPhotoIncludeSide: true,
    VehicleRegistrationIncludeProofOfInsurance: null,
    VehicleRegistrationIncludeCheckbox: true,
    VehicleRegistrationCheckboxText:
      'I agree to maintain liability and comprehensive insurance coverage on any vehicle used, parked or stored on site for the duration of my lease.',
    PetInclude: true,
    PetIncludeType: true,
    PetIncludeName: true,
    PetIncludeAge: true,
    PetIncludeGender: true,
    PetIncludeBreed: true,
    PetIncludeWeight: true,
    PetIncludeColor: true,
    PetIncludeProofOfVaccinations: true,
    PetCheckboxInclude1: null,
    PetCheckboxText1: null,
    PetCheckboxInclude2: null,
    PetCheckboxText2: null,
    PetCheckboxInclude3: null,
    PetCheckboxText3: null,
    PetPhotoIncludeAny: true,
    PetPhotoIncludeFace: true,
    PetPhotoIncludeSitting: true,
    PetPhotoIncludeStanding: true,
    PetInterviewRequired: true,
    PetInterviewSendReminder: null,
    PetInterviewText:
      'All dogs must be brought in to the leasing office for a pet interview before you move in. Please contact the leasing office to schedule a time to bring them in.',
    PetScreeningRequired: null,
    PetScreeningText: null,
    PetPolicyIncludeCheckbox: true,
    PetPolicyText:
      'I understand that this community has a strict pet policy in place as outlined in my lease and I am confirming that myself and my pet(s) will remain in full compliance with that policy through out the term of my lease.',
    PetLiabilityIncludeCheckbox: true,
    PetLiabilityText:
      'I accept and acknowledge personal responsibility and liability for any injury or damage caused as a result of the wrongful or aggressive behavior of my dog, whether the loss occurs as a result of biting, jumping, scratching, digging, charging or any other behavior.',
    ElevatorReservationInclude: false,
    ElevatorRegistrationText:
      'You must schedule your reservation prior to your move. Please select the three different 2-hour windows that work best for you based on desirability, with the first choice being the most desirable, and hit Submit. Someone with the property will then get back to you to confirm your reservation.',
    LoadingDockRegistrationInclude: null,
    LoadingDockRegistrationText: null,
    TemporaryParkingPermitInclude: null,
    TemporaryParkingPermitText: null,
    ResidentOnboardingOther1: null,
    ResidentOnboardingOther2: null,
    ResidentOnboardingOther3: null,
    IsServiceAvailable: null,
    IsDefaultProviderCorrect: true,
    IsServiceRequired: true,
    IsServiceIncludedInRent: null,
    IsServiceProofRequired: true,
    IsServiceProofDeliver: false,
    IsServiceProofUpload: true,
    IsServiceProofUseUrl: false,
    IsServicePaidToThirdParty: null,
    IsServicePaidWithRent: null,
    IsServicePaidToOther: null,
    IsServiceOfferedOffsiteLink: null,
    ServiceProofIncludeProviderName: true,
    ServiceProofIncludeAccountNumber: true,
    ServiceProofIncludeStartDate: true,
    ServiceProofIncludeEndDate: null,
    ServiceProofIncludeVeificationOfService: null,
    ServiceProofIncludeDocUpload: true,
    ServiceProofAlt1: null,
    ServiceProofAlt2: null,
    ServiceProofAlt3: null,
    ServiceProofAlt4: null,
    ServiceProofAlt5: null,
    ServiceProofUrl: null,
    OffisteLinkUrl: null,
    CustomMessage: null,
    ProviderNameEntered: null,
    ServiceAddendumFile: null,
    HasPreferredProvider: null,
    InsuranceIsInterestedPartyRequired: null,
    InsuranceIsMinCoverageRequired: null,
    InsuranceMinCoverageLiabilityRequired: null,
    InsuranceMinCoverageLiabilityValue: null,
    InsuranceMinCoveragePropertyRequired: null,
    InsuranceMinCoveragePropertyValue: null,
    InsuranceMinCoverageOtherRequired: null,
    InsuranceMinCoverageOtherLabel: null,
    InsuranceMinCoverageOtherValue: null,
    InsuranceInterestedPartyText: null,
    InternetHasInHousePackage: null,
    InternetIsAllowSatellite: null,
    StorageHasOnsite: null,
    StorageHasGarages: null,
    StorageHasParking: null,
    StorageIsInSoftware: null,
    StorageShowOnsite: null,
    InternetIsPartOfRent: null,
    InternetIsMarketingAgreement: null,
    InternetIsSpecialPricing: null,
    InternetSpecialPricingUrl: null,
    IsPreferredProviderExclusive: null,
    CheckboxInclude: true,
    CheckboxText:
      'I understand that as part of my lease I agreed to maintain Natural Gas Service in my name for the duration of my lease. I further understand and agree that if I fail to establish or maintain service in my name that the property may bill me for any charges they pay on my behalf, plus a reasonable administrative fee.',
    ResidentOnboardingStatusNote: null,
    ResidentWalkthroughID: null
  },
  {
    DisplayOrder: 3,
    Grouping: 'provider-service',
    Description: 'Renters Insurance',
    ResidentOnboardingStatusID: 3,
    Reason: '',
    ResidentOnboardingStatusName: 'Completed',
    ProviderServiceID: 37,
    ResidentVehicleID: null,
    ResidentPetID: null,
    OnboardingColumn: 'Renters Insurance',
    VehicleRegistrationInclude: true,
    VehicleRegistrationIncludeYear: true,
    VehicleRegistrationIncludeMake: true,
    VehicleRegistrationIncludeModel: true,
    VehicleRegistrationIncludeColor: true,
    VehicleRegistrationIncludeLicensePlateNumber: true,
    VehicleRegistrationIncludeLicensePlateState: true,
    VehicleRegistrationIncludeVIN: null,
    VehicleRegistrationPhotoIncludeAny: true,
    VehicleRegistrationPhotoIncludeFront: true,
    VehicleRegistrationPhotoIncludeBack: true,
    VehicleRegistrationPhotoIncludeSide: true,
    VehicleRegistrationIncludeProofOfInsurance: null,
    VehicleRegistrationIncludeCheckbox: true,
    VehicleRegistrationCheckboxText:
      'I agree to maintain liability and comprehensive insurance coverage on any vehicle used, parked or stored on site for the duration of my lease.',
    PetInclude: true,
    PetIncludeType: true,
    PetIncludeName: true,
    PetIncludeAge: true,
    PetIncludeGender: true,
    PetIncludeBreed: true,
    PetIncludeWeight: true,
    PetIncludeColor: true,
    PetIncludeProofOfVaccinations: true,
    PetCheckboxInclude1: null,
    PetCheckboxText1: null,
    PetCheckboxInclude2: null,
    PetCheckboxText2: null,
    PetCheckboxInclude3: null,
    PetCheckboxText3: null,
    PetPhotoIncludeAny: true,
    PetPhotoIncludeFace: true,
    PetPhotoIncludeSitting: true,
    PetPhotoIncludeStanding: true,
    PetInterviewRequired: true,
    PetInterviewSendReminder: null,
    PetInterviewText:
      'All dogs must be brought in to the leasing office for a pet interview before you move in. Please contact the leasing office to schedule a time to bring them in.',
    PetScreeningRequired: null,
    PetScreeningText: null,
    PetPolicyIncludeCheckbox: true,
    PetPolicyText:
      'I understand that this community has a strict pet policy in place as outlined in my lease and I am confirming that myself and my pet(s) will remain in full compliance with that policy through out the term of my lease.',
    PetLiabilityIncludeCheckbox: true,
    PetLiabilityText:
      'I accept and acknowledge personal responsibility and liability for any injury or damage caused as a result of the wrongful or aggressive behavior of my dog, whether the loss occurs as a result of biting, jumping, scratching, digging, charging or any other behavior.',
    ElevatorReservationInclude: false,
    ElevatorRegistrationText:
      'You must schedule your reservation prior to your move. Please select the three different 2-hour windows that work best for you based on desirability, with the first choice being the most desirable, and hit Submit. Someone with the property will then get back to you to confirm your reservation.',
    LoadingDockRegistrationInclude: null,
    LoadingDockRegistrationText: null,
    TemporaryParkingPermitInclude: null,
    TemporaryParkingPermitText: null,
    ResidentOnboardingOther1: null,
    ResidentOnboardingOther2: null,
    ResidentOnboardingOther3: null,
    IsServiceAvailable: null,
    IsDefaultProviderCorrect: null,
    IsServiceRequired: true,
    IsServiceIncludedInRent: null,
    IsServiceProofRequired: true,
    IsServiceProofDeliver: false,
    IsServiceProofUpload: true,
    IsServiceProofUseUrl: false,
    IsServicePaidToThirdParty: null,
    IsServicePaidWithRent: null,
    IsServicePaidToOther: null,
    IsServiceOfferedOffsiteLink: null,
    ServiceProofIncludeProviderName: true,
    ServiceProofIncludeAccountNumber: true,
    ServiceProofIncludeStartDate: true,
    ServiceProofIncludeEndDate: true,
    ServiceProofIncludeVeificationOfService: null,
    ServiceProofIncludeDocUpload: true,
    ServiceProofAlt1: null,
    ServiceProofAlt2: null,
    ServiceProofAlt3: null,
    ServiceProofAlt4: null,
    ServiceProofAlt5: null,
    ServiceProofUrl: null,
    OffisteLinkUrl: null,
    CustomMessage: null,
    ProviderNameEntered: null,
    ServiceAddendumFile: null,
    HasPreferredProvider: true,
    InsuranceIsInterestedPartyRequired: true,
    InsuranceIsMinCoverageRequired: true,
    InsuranceMinCoverageLiabilityRequired: true,
    InsuranceMinCoverageLiabilityValue: 100000,
    InsuranceMinCoveragePropertyRequired: true,
    InsuranceMinCoveragePropertyValue: 5000,
    InsuranceMinCoverageOtherRequired: null,
    InsuranceMinCoverageOtherLabel: null,
    InsuranceMinCoverageOtherValue: null,
    InsuranceInterestedPartyText:
      'Camelback Square LLC\n1714 21st Street\nSacramento, CA 95811',
    InternetHasInHousePackage: null,
    InternetIsAllowSatellite: null,
    StorageHasOnsite: null,
    StorageHasGarages: null,
    StorageHasParking: null,
    StorageIsInSoftware: null,
    StorageShowOnsite: null,
    InternetIsPartOfRent: null,
    InternetIsMarketingAgreement: null,
    InternetIsSpecialPricing: null,
    InternetSpecialPricingUrl: null,
    IsPreferredProviderExclusive: false,
    CheckboxInclude: true,
    CheckboxText:
      'I understand that as part of my lease I agreed to maintain Renters Insurance (Preferred) Service in my name for the duration of my lease. I further understand and agree that if I fail to establish or maintain service in my name that the property may bill me for any charges they pay on my behalf, plus a reasonable administrative fee.',
    ResidentOnboardingStatusNote: '',
    ResidentWalkthroughID: null
  },
  {
    DisplayOrder: 4,
    Grouping: 'vehicle',
    Description: 'Vehicle Registration - Vehicle #1',
    ResidentOnboardingStatusID: 3,
    Reason: '',
    ResidentOnboardingStatusName: 'Completed',
    ProviderServiceID: null,
    ResidentVehicleID: 83,
    ResidentPetID: null,
    OnboardingColumn: 'Vehicle Registration',
    VehicleRegistrationInclude: true,
    VehicleRegistrationIncludeYear: true,
    VehicleRegistrationIncludeMake: true,
    VehicleRegistrationIncludeModel: true,
    VehicleRegistrationIncludeColor: true,
    VehicleRegistrationIncludeLicensePlateNumber: true,
    VehicleRegistrationIncludeLicensePlateState: true,
    VehicleRegistrationIncludeVIN: null,
    VehicleRegistrationPhotoIncludeAny: true,
    VehicleRegistrationPhotoIncludeFront: true,
    VehicleRegistrationPhotoIncludeBack: true,
    VehicleRegistrationPhotoIncludeSide: true,
    VehicleRegistrationIncludeProofOfInsurance: null,
    VehicleRegistrationIncludeCheckbox: true,
    VehicleRegistrationCheckboxText:
      'I agree to maintain liability and comprehensive insurance coverage on any vehicle used, parked or stored on site for the duration of my lease.',
    PetInclude: true,
    PetIncludeType: true,
    PetIncludeName: true,
    PetIncludeAge: true,
    PetIncludeGender: true,
    PetIncludeBreed: true,
    PetIncludeWeight: true,
    PetIncludeColor: true,
    PetIncludeProofOfVaccinations: true,
    PetCheckboxInclude1: null,
    PetCheckboxText1: null,
    PetCheckboxInclude2: null,
    PetCheckboxText2: null,
    PetCheckboxInclude3: null,
    PetCheckboxText3: null,
    PetPhotoIncludeAny: true,
    PetPhotoIncludeFace: true,
    PetPhotoIncludeSitting: true,
    PetPhotoIncludeStanding: true,
    PetInterviewRequired: true,
    PetInterviewSendReminder: null,
    PetInterviewText:
      'All dogs must be brought in to the leasing office for a pet interview before you move in. Please contact the leasing office to schedule a time to bring them in.',
    PetScreeningRequired: null,
    PetScreeningText: null,
    PetPolicyIncludeCheckbox: true,
    PetPolicyText:
      'I understand that this community has a strict pet policy in place as outlined in my lease and I am confirming that myself and my pet(s) will remain in full compliance with that policy through out the term of my lease.',
    PetLiabilityIncludeCheckbox: true,
    PetLiabilityText:
      'I accept and acknowledge personal responsibility and liability for any injury or damage caused as a result of the wrongful or aggressive behavior of my dog, whether the loss occurs as a result of biting, jumping, scratching, digging, charging or any other behavior.',
    ElevatorReservationInclude: false,
    ElevatorRegistrationText:
      'You must schedule your reservation prior to your move. Please select the three different 2-hour windows that work best for you based on desirability, with the first choice being the most desirable, and hit Submit. Someone with the property will then get back to you to confirm your reservation.',
    LoadingDockRegistrationInclude: null,
    LoadingDockRegistrationText: null,
    TemporaryParkingPermitInclude: null,
    TemporaryParkingPermitText: null,
    ResidentOnboardingOther1: null,
    ResidentOnboardingOther2: null,
    ResidentOnboardingOther3: null,
    IsServiceAvailable: null,
    IsDefaultProviderCorrect: null,
    IsServiceRequired: null,
    IsServiceIncludedInRent: null,
    IsServiceProofRequired: null,
    IsServiceProofDeliver: null,
    IsServiceProofUpload: null,
    IsServiceProofUseUrl: null,
    IsServicePaidToThirdParty: null,
    IsServicePaidWithRent: null,
    IsServicePaidToOther: null,
    IsServiceOfferedOffsiteLink: null,
    ServiceProofIncludeProviderName: null,
    ServiceProofIncludeAccountNumber: null,
    ServiceProofIncludeStartDate: null,
    ServiceProofIncludeEndDate: null,
    ServiceProofIncludeVeificationOfService: null,
    ServiceProofIncludeDocUpload: null,
    ServiceProofAlt1: null,
    ServiceProofAlt2: null,
    ServiceProofAlt3: null,
    ServiceProofAlt4: null,
    ServiceProofAlt5: null,
    ServiceProofUrl: null,
    OffisteLinkUrl: null,
    CustomMessage: null,
    ProviderNameEntered: null,
    ServiceAddendumFile: null,
    HasPreferredProvider: null,
    InsuranceIsInterestedPartyRequired: null,
    InsuranceIsMinCoverageRequired: null,
    InsuranceMinCoverageLiabilityRequired: null,
    InsuranceMinCoverageLiabilityValue: null,
    InsuranceMinCoveragePropertyRequired: null,
    InsuranceMinCoveragePropertyValue: null,
    InsuranceMinCoverageOtherRequired: null,
    InsuranceMinCoverageOtherLabel: null,
    InsuranceMinCoverageOtherValue: null,
    InsuranceInterestedPartyText: null,
    InternetHasInHousePackage: null,
    InternetIsAllowSatellite: null,
    StorageHasOnsite: null,
    StorageHasGarages: null,
    StorageHasParking: null,
    StorageIsInSoftware: null,
    StorageShowOnsite: null,
    InternetIsPartOfRent: null,
    InternetIsMarketingAgreement: null,
    InternetIsSpecialPricing: null,
    InternetSpecialPricingUrl: null,
    IsPreferredProviderExclusive: null,
    CheckboxInclude: null,
    CheckboxText: null,
    ResidentOnboardingStatusNote: '',
    ResidentWalkthroughID: null
  },
  {
    DisplayOrder: 5,
    Grouping: 'pet',
    Description: 'Pet Registration',
    ResidentOnboardingStatusID: 3,
    Reason: 'No pets',
    ResidentOnboardingStatusName: 'Completed',
    ProviderServiceID: null,
    ResidentVehicleID: null,
    ResidentPetID: null,
    OnboardingColumn: 'Pet Registration',
    VehicleRegistrationInclude: true,
    VehicleRegistrationIncludeYear: true,
    VehicleRegistrationIncludeMake: true,
    VehicleRegistrationIncludeModel: true,
    VehicleRegistrationIncludeColor: true,
    VehicleRegistrationIncludeLicensePlateNumber: true,
    VehicleRegistrationIncludeLicensePlateState: true,
    VehicleRegistrationIncludeVIN: null,
    VehicleRegistrationPhotoIncludeAny: true,
    VehicleRegistrationPhotoIncludeFront: true,
    VehicleRegistrationPhotoIncludeBack: true,
    VehicleRegistrationPhotoIncludeSide: true,
    VehicleRegistrationIncludeProofOfInsurance: null,
    VehicleRegistrationIncludeCheckbox: true,
    VehicleRegistrationCheckboxText:
      'I agree to maintain liability and comprehensive insurance coverage on any vehicle used, parked or stored on site for the duration of my lease.',
    PetInclude: true,
    PetIncludeType: true,
    PetIncludeName: true,
    PetIncludeAge: true,
    PetIncludeGender: true,
    PetIncludeBreed: true,
    PetIncludeWeight: true,
    PetIncludeColor: true,
    PetIncludeProofOfVaccinations: true,
    PetCheckboxInclude1: null,
    PetCheckboxText1: null,
    PetCheckboxInclude2: null,
    PetCheckboxText2: null,
    PetCheckboxInclude3: null,
    PetCheckboxText3: null,
    PetPhotoIncludeAny: true,
    PetPhotoIncludeFace: true,
    PetPhotoIncludeSitting: true,
    PetPhotoIncludeStanding: true,
    PetInterviewRequired: true,
    PetInterviewSendReminder: null,
    PetInterviewText:
      'All dogs must be brought in to the leasing office for a pet interview before you move in. Please contact the leasing office to schedule a time to bring them in.',
    PetScreeningRequired: null,
    PetScreeningText: null,
    PetPolicyIncludeCheckbox: true,
    PetPolicyText:
      'I understand that this community has a strict pet policy in place as outlined in my lease and I am confirming that myself and my pet(s) will remain in full compliance with that policy through out the term of my lease.',
    PetLiabilityIncludeCheckbox: true,
    PetLiabilityText:
      'I accept and acknowledge personal responsibility and liability for any injury or damage caused as a result of the wrongful or aggressive behavior of my dog, whether the loss occurs as a result of biting, jumping, scratching, digging, charging or any other behavior.',
    ElevatorReservationInclude: false,
    ElevatorRegistrationText:
      'You must schedule your reservation prior to your move. Please select the three different 2-hour windows that work best for you based on desirability, with the first choice being the most desirable, and hit Submit. Someone with the property will then get back to you to confirm your reservation.',
    LoadingDockRegistrationInclude: null,
    LoadingDockRegistrationText: null,
    TemporaryParkingPermitInclude: null,
    TemporaryParkingPermitText: null,
    ResidentOnboardingOther1: null,
    ResidentOnboardingOther2: null,
    ResidentOnboardingOther3: null,
    IsServiceAvailable: null,
    IsDefaultProviderCorrect: null,
    IsServiceRequired: null,
    IsServiceIncludedInRent: null,
    IsServiceProofRequired: null,
    IsServiceProofDeliver: null,
    IsServiceProofUpload: null,
    IsServiceProofUseUrl: null,
    IsServicePaidToThirdParty: null,
    IsServicePaidWithRent: null,
    IsServicePaidToOther: null,
    IsServiceOfferedOffsiteLink: null,
    ServiceProofIncludeProviderName: null,
    ServiceProofIncludeAccountNumber: null,
    ServiceProofIncludeStartDate: null,
    ServiceProofIncludeEndDate: null,
    ServiceProofIncludeVeificationOfService: null,
    ServiceProofIncludeDocUpload: null,
    ServiceProofAlt1: null,
    ServiceProofAlt2: null,
    ServiceProofAlt3: null,
    ServiceProofAlt4: null,
    ServiceProofAlt5: null,
    ServiceProofUrl: null,
    OffisteLinkUrl: null,
    CustomMessage: null,
    ProviderNameEntered: null,
    ServiceAddendumFile: null,
    HasPreferredProvider: null,
    InsuranceIsInterestedPartyRequired: null,
    InsuranceIsMinCoverageRequired: null,
    InsuranceMinCoverageLiabilityRequired: null,
    InsuranceMinCoverageLiabilityValue: null,
    InsuranceMinCoveragePropertyRequired: null,
    InsuranceMinCoveragePropertyValue: null,
    InsuranceMinCoverageOtherRequired: null,
    InsuranceMinCoverageOtherLabel: null,
    InsuranceMinCoverageOtherValue: null,
    InsuranceInterestedPartyText: null,
    InternetHasInHousePackage: null,
    InternetIsAllowSatellite: null,
    StorageHasOnsite: null,
    StorageHasGarages: null,
    StorageHasParking: null,
    StorageIsInSoftware: null,
    StorageShowOnsite: null,
    InternetIsPartOfRent: null,
    InternetIsMarketingAgreement: null,
    InternetIsSpecialPricing: null,
    InternetSpecialPricingUrl: null,
    IsPreferredProviderExclusive: null,
    CheckboxInclude: null,
    CheckboxText: null,
    ResidentOnboardingStatusNote: null,
    ResidentWalkthroughID: null
  },
  {
    DisplayOrder: 6,
    Grouping: 'walkthrough',
    Description: 'Move-in Condition Form',
    ResidentOnboardingStatusID: 1,
    Reason: 'delete',
    ResidentOnboardingStatusName: 'Action Required',
    ProviderServiceID: null,
    ResidentVehicleID: null,
    ResidentPetID: null,
    OnboardingColumn: 'Move-in Condition Form',
    VehicleRegistrationInclude: true,
    VehicleRegistrationIncludeYear: true,
    VehicleRegistrationIncludeMake: true,
    VehicleRegistrationIncludeModel: true,
    VehicleRegistrationIncludeColor: true,
    VehicleRegistrationIncludeLicensePlateNumber: true,
    VehicleRegistrationIncludeLicensePlateState: true,
    VehicleRegistrationIncludeVIN: null,
    VehicleRegistrationPhotoIncludeAny: true,
    VehicleRegistrationPhotoIncludeFront: true,
    VehicleRegistrationPhotoIncludeBack: true,
    VehicleRegistrationPhotoIncludeSide: true,
    VehicleRegistrationIncludeProofOfInsurance: null,
    VehicleRegistrationIncludeCheckbox: true,
    VehicleRegistrationCheckboxText:
      'I agree to maintain liability and comprehensive insurance coverage on any vehicle used, parked or stored on site for the duration of my lease.',
    PetInclude: true,
    PetIncludeType: true,
    PetIncludeName: true,
    PetIncludeAge: true,
    PetIncludeGender: true,
    PetIncludeBreed: true,
    PetIncludeWeight: true,
    PetIncludeColor: true,
    PetIncludeProofOfVaccinations: true,
    PetCheckboxInclude1: null,
    PetCheckboxText1: null,
    PetCheckboxInclude2: null,
    PetCheckboxText2: null,
    PetCheckboxInclude3: null,
    PetCheckboxText3: null,
    PetPhotoIncludeAny: true,
    PetPhotoIncludeFace: true,
    PetPhotoIncludeSitting: true,
    PetPhotoIncludeStanding: true,
    PetInterviewRequired: true,
    PetInterviewSendReminder: null,
    PetInterviewText:
      'All dogs must be brought in to the leasing office for a pet interview before you move in. Please contact the leasing office to schedule a time to bring them in.',
    PetScreeningRequired: null,
    PetScreeningText: null,
    PetPolicyIncludeCheckbox: true,
    PetPolicyText:
      'I understand that this community has a strict pet policy in place as outlined in my lease and I am confirming that myself and my pet(s) will remain in full compliance with that policy through out the term of my lease.',
    PetLiabilityIncludeCheckbox: true,
    PetLiabilityText:
      'I accept and acknowledge personal responsibility and liability for any injury or damage caused as a result of the wrongful or aggressive behavior of my dog, whether the loss occurs as a result of biting, jumping, scratching, digging, charging or any other behavior.',
    ElevatorReservationInclude: false,
    ElevatorRegistrationText:
      'You must schedule your reservation prior to your move. Please select the three different 2-hour windows that work best for you based on desirability, with the first choice being the most desirable, and hit Submit. Someone with the property will then get back to you to confirm your reservation.',
    LoadingDockRegistrationInclude: null,
    LoadingDockRegistrationText: null,
    TemporaryParkingPermitInclude: null,
    TemporaryParkingPermitText: null,
    ResidentOnboardingOther1: null,
    ResidentOnboardingOther2: null,
    ResidentOnboardingOther3: null,
    IsServiceAvailable: null,
    IsDefaultProviderCorrect: null,
    IsServiceRequired: null,
    IsServiceIncludedInRent: null,
    IsServiceProofRequired: null,
    IsServiceProofDeliver: null,
    IsServiceProofUpload: null,
    IsServiceProofUseUrl: null,
    IsServicePaidToThirdParty: null,
    IsServicePaidWithRent: null,
    IsServicePaidToOther: null,
    IsServiceOfferedOffsiteLink: null,
    ServiceProofIncludeProviderName: null,
    ServiceProofIncludeAccountNumber: null,
    ServiceProofIncludeStartDate: null,
    ServiceProofIncludeEndDate: null,
    ServiceProofIncludeVeificationOfService: null,
    ServiceProofIncludeDocUpload: null,
    ServiceProofAlt1: null,
    ServiceProofAlt2: null,
    ServiceProofAlt3: null,
    ServiceProofAlt4: null,
    ServiceProofAlt5: null,
    ServiceProofUrl: null,
    OffisteLinkUrl: null,
    CustomMessage: null,
    ProviderNameEntered: null,
    ServiceAddendumFile: null,
    HasPreferredProvider: null,
    InsuranceIsInterestedPartyRequired: null,
    InsuranceIsMinCoverageRequired: null,
    InsuranceMinCoverageLiabilityRequired: null,
    InsuranceMinCoverageLiabilityValue: null,
    InsuranceMinCoveragePropertyRequired: null,
    InsuranceMinCoveragePropertyValue: null,
    InsuranceMinCoverageOtherRequired: null,
    InsuranceMinCoverageOtherLabel: null,
    InsuranceMinCoverageOtherValue: null,
    InsuranceInterestedPartyText: null,
    InternetHasInHousePackage: null,
    InternetIsAllowSatellite: null,
    StorageHasOnsite: null,
    StorageHasGarages: null,
    StorageHasParking: null,
    StorageIsInSoftware: null,
    StorageShowOnsite: null,
    InternetIsPartOfRent: null,
    InternetIsMarketingAgreement: null,
    InternetIsSpecialPricing: null,
    InternetSpecialPricingUrl: null,
    IsPreferredProviderExclusive: null,
    CheckboxInclude: null,
    CheckboxText: null,
    ResidentOnboardingStatusNote: 'delete',
    ResidentWalkthroughID: 4
  }
];
