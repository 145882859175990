import React, { useEffect, useRef, useState } from 'react';
import { TextField, InputAdornment } from '@material-ui/core';

function UnitCountField({ value, onChange, formatNumber }) {
  const [hasFocus, setHasFocus] = useState(null);
  return (
    <TextField
      variant="standard"
      type={hasFocus ? 'number' : 'text'}
      value={hasFocus ? value : formatNumber(value, 0)}
      onFocus={() => setHasFocus(true)}
      onBlur={() => setHasFocus(false)}
      onChange={(event) => onChange(event)}
    />
  );
}

function RevenueCalc({
  siteConfig,
  company,
  calcs,
  updateCalcs,
  formatNumber,
  tablet,
  email
}) {
  return (
    <div
      className={'calc' + (tablet ? ' tablet' : '') + (email ? ' email' : '')}>
      <table align="center">
        <thead>
          <tr>
            <th className="title" rowSpan="2" colSpan="2">
              {!email && company.LogoFileWeb ? (
                <img
                  src={company.LogoFileWeb}
                  alt={company.ManagementCompanyName}
                />
              ) : (
                company.ManagementCompanyName
              )}
            </th>
            <th className="value">Annual Apartment Turn Rate</th>
            <th className="value">Resident Engagement Rate</th>
          </tr>
          <tr>
            <th className="value">
              {email ? (
                <span style={{ fontWeight: 'normal' }}>
                  {formatNumber(calcs.PercentTurn, 0) + '%'}
                </span>
              ) : (
                <TextField
                  variant="standard"
                  type="number"
                  value={calcs.PercentTurn}
                  onChange={(event) =>
                    updateCalcs({
                      PercentTurn: event.target.value
                        ? event.target.value > 100
                          ? 100
                          : parseFloat(event.target.value)
                        : 0
                    })
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    )
                  }}
                />
              )}
            </th>
            <th className="value">
              {email ? (
                <span style={{ fontWeight: 'normal' }}>
                  {formatNumber(calcs.PercentEngagement, 0) + '%'}
                </span>
              ) : (
                <TextField
                  variant="standard"
                  type="number"
                  value={calcs.PercentEngagement}
                  onChange={(event) =>
                    updateCalcs({
                      PercentEngagement: event.target.value
                        ? event.target.value > 100
                          ? 100
                          : parseFloat(event.target.value)
                        : 0
                    })
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    )
                  }}
                />
              )}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Total Units</td>
            <td className="value">
              {email ? (
                <span>{formatNumber(calcs.CountUnit, 0)}</span>
              ) : (
                <UnitCountField
                  value={calcs.CountUnit}
                  onChange={(event) =>
                    updateCalcs({
                      CountUnit: event.target.value ? event.target.value : 0
                    })
                  }
                  formatNumber={formatNumber}
                />
              )}
            </td>
            <td className="value">{formatNumber(calcs.CountUnitTurn, 0)}</td>
            <td className="value">
              {formatNumber(calcs.CountUnitEngagement, 0)}
            </td>
          </tr>

          <tr>
            <td>Electric Choice Units (TX)</td>
            <td className="value">
              {email ? (
                <span>{formatNumber(calcs.CountTexasDeregulatedUnit, 0)}</span>
              ) : (
                <UnitCountField
                  value={calcs.CountTexasDeregulatedUnit}
                  onChange={(event) =>
                    updateCalcs({
                      CountTexasDeregulatedUnit: event.target.value
                        ? event.target.value
                        : 0
                    })
                  }
                  formatNumber={formatNumber}
                />
              )}
            </td>
            <td className="value">
              {formatNumber(calcs.CountTexasUnitTurn, 0)}
            </td>
            <td className="value">
              {formatNumber(calcs.CountTexasUnitEngagement, 0)}
            </td>
          </tr>

          <tr className="spacer">
            <td colSpan="4"></td>
          </tr>
          <tr>
            <th></th>
            <th className="value">Resident Engagements</th>
            <th className="value">Average Commission</th>
            <th className="value">Total Annual Revenue</th>
          </tr>
          <tr>
            <td>Internet Commissions</td>
            <td className="value">
              {formatNumber(calcs.CountUnitEngagement, 0)}
            </td>
            <td className="value">
              ${formatNumber(calcs.AverageCommissionInternet, 2)}
            </td>
            <td className="value">
              ${formatNumber(calcs.TotalCommissionInternet, 2)}
            </td>
          </tr>

          <tr>
            <td>Electric Commissions</td>
            <td className="value">
              {formatNumber(calcs.CountTexasUnitEngagement, 0)}
            </td>
            <td className="value">
              ${formatNumber(calcs.AverageCommissionElectric, 2)}
            </td>
            <td className="value">
              ${formatNumber(calcs.TotalCommissionElectric, 2)}
            </td>
          </tr>
          <tr>
            <td></td>
            <td className="value"></td>
            <td className="value"></td>
            <td className="value total">
              ${formatNumber(calcs.TotalCommission, 2)}
            </td>
          </tr>

          <tr className="revenue-share">
            <td colSpan="3">
              {!email ? <div className="border"></div> : null}
              Your Revenue Share on Commissions
            </td>
            <td className="value">
              ${formatNumber(calcs.RevenueShareTotal, 2)}
            </td>
          </tr>
          {calcs.SuggestedFeePet && calcs.SuggestedFeeVehicle ? (
            <>
              <tr className="spacer">
                <td colSpan="4"></td>
              </tr>
              <tr className="spacer">
                <th colSpan="4">Additional Revenue Share Opportunities</th>
              </tr>
              <tr>
                <td>Pet Registrations</td>
                <td className="value">
                  {formatNumber(calcs.TotalEngagementPet, 0)}
                </td>
                <td className="value">
                  ${formatNumber(calcs.SuggestedFeePet, 2)}
                </td>
                <td className="value">
                  ${formatNumber(calcs.RevenueSharePet, 2)}
                </td>
              </tr>
              <tr>
                <td>Vehicle Registrations</td>
                <td className="value">
                  {formatNumber(calcs.TotalEngagementVehicle, 0)}
                </td>
                <td className="value">
                  ${formatNumber(calcs.SuggestedFeeVehicle, 2)}
                </td>
                <td className="value">
                  ${formatNumber(calcs.RevenueShareVehicle, 2)}
                </td>
              </tr>
              <tr>
                <td></td>
                <td className="value"></td>
                <td className="value"></td>
                <td className="value total">
                  ${formatNumber(calcs.RevenueShareTotalAdditional, 2)}
                </td>
              </tr>
            </>
          ) : null}
        </tbody>
      </table>
    </div>
  );
}

export default RevenueCalc;
