import React, { useState } from 'react';
import { withPrefix } from 'gatsby-link';

import { IconButton, Button } from '@material-ui/core';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
const fileList = [
  '0001.jpg?v=1',
  '0002.jpg?v=1',
  '0003.jpg?v=1',
  '0004.jpg?v=1',
  '0005.jpg?v=1',
  '0006.jpg?v=1',
  '0007.jpg?v=1',
  '0008.jpg?v=1',
  '0009.jpg?v=1',
  '0010.jpg?v=1',
  '0011.jpg?v=1',
  '0012.jpg?v=1',
  '0013.jpg?v=1'
];

function Screenshot({ img }) {
  return (
    <div
      className="secondary-screenshot"
      onClick={() => document.getElementById('enlargeDocDash').click()}>
      <img src={withPrefix('/images/mi-landing/tablet-screenshot_90.svg')} />

      <div className="viewport">
        <img src={img} />
      </div>
    </div>
  );
}

function DocViewer({ visible, setShowScreenshotPopup, children }) {
  const [activeFile, setActiveFile] = useState(0);
  const [expanded, setExpanded] = useState(false);

  function nextFile(direction) {
    setActiveFile(activeFile + direction);
  }

  function showPopup() {
    setShowScreenshotPopup({
      urlList: fileList.map((fileName) =>
        withPrefix('/images/company/doc-download/' + fileName)
      ),
      label: 'Documents Automatically Posted to Property Management System'
    });
  }

  return (
    <div
      id="docViewer"
      className={'doc-viewer-container' + (expanded ? ' expanded' : '')}>
      <div className="doc-viewer-content">
        <h3>Document Management</h3>

        <p>
          As residents complete each task, a PDF copy of their submittal is
          created and automatically posted to their document folder in your
          property management system.{' '}
        </p>

        <p>
          You can choose to have these items posted one at a time as they’re
          completed, or as one combined document as shown here.
        </p>

        <Screenshot
          img={withPrefix(
            '/images/company/screens/Document Folder screen shot.png'
          )}
        />

        <Button
          className="enlarge"
          id="enlargeDocDash"
          onClick={() =>
            setShowScreenshotPopup({
              urlList: fileList.map((fileName) =>
                withPrefix(
                  '/images/company/screens/Document Folder screen shot.png'
                )
              ),
              label: "Property's Onboarding Dashboard"
            })
          }>
          <ZoomInIcon />
          Enlarge Image
        </Button>
      </div>

      <div
        className={'doc-viewer ' + (visible ? ' visible' : '')}
        onClick={() => showPopup()}>
        <div className="caption">
          Documents Automatically Posted to Property Management System
        </div>

        <ul className="doc-list">
          {/* {fileList.map((file, fileIndex) => ( */}
          <li className={'active'}>
            <img
              src={withPrefix('/images/company/doc-download/' + fileList[0])}
            />
          </li>
          {/* ))} */}
        </ul>
        {/* 
        {activeFile > 0 ? (
          <IconButton
            className="left"
            // onClick={() => {
            //   nextFile(-1);
            //   setExpanded(true);
            // }}
          >
            <ChevronLeftIcon />
          </IconButton>
        ) : null}

        {activeFile < fileList.length - 1 ? (
          <IconButton
            className="right"
            // onClick={() => {
            //   nextFile(1);
            //   setExpanded(true);
            // }}
          >
            <ChevronRightIcon />
          </IconButton>
        ) : null} */}

        <Button
          className="enlarge"
          href={withPrefix(
            '/images/company/doc-download/TIN3009886_MovingINPackage.pdf'
          )}
          onClick={(event) => event.stopPropagation()}
          target="_blank">
          <PictureAsPdfIcon style={{ marginRight: '5px' }} /> View Sample PDF
        </Button>
      </div>
    </div>
  );
}

export default DocViewer;
