import React from 'react';
import { withPrefix } from 'gatsby-link';
import { Button } from '@material-ui/core';
import ZoomInIcon from '@mui/icons-material/ZoomIn';

function DashboardScreen({ company, mobile, setShowScreenshotPopup }) {
  return (
    <div
      className={'dashboard-screen' + (mobile ? ' mobile' : '')}
      onClick={() => document.getElementById('enlargeDashboard').click()}>
      <div className="screenshot">
        <img
          src={withPrefix(
            mobile
              ? '/images/mi-landing/tablet-screenshot_90.svg'
              : '/images/mi-landing/tablet-screenshot.svg'
          )}
        />

        <div className="viewport">
          <img
            src={withPrefix(
              company && company.CountTexasDeregulatedUnit
                ? '/images/company/screens/Resident Dashboard.png'
                : '/images/company/screens/Resident Dashboard.png'
            )}
          />
        </div>
      </div>

      <Button
        className="enlarge"
        onClick={() =>
          setShowScreenshotPopup({
            url: withPrefix(
              company && company.CountTexasDeregulatedUnit
                ? '/images/company/screens/Resident Dashboard.png'
                : '/images/company/screens/Resident Dashboard.png'
            ),
            label: 'Resident Dashboard'
          })
        }
        id="enlargeDashboard">
        <ZoomInIcon />
        Enlarge Image
      </Button>
    </div>
  );
}

export default DashboardScreen;
