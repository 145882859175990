import React, { useRef, useEffect, useState } from 'react';
import { withPrefix } from 'gatsby-link';

import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import GradingIcon from '@mui/icons-material/Grading';
import { useOnScreen } from '../../api/dataApi';
import { Button, IconButton } from '@material-ui/core';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

import { scrollToElementInPage } from '../../api/dataApi';

import DocViewer from './DocViewer';

import ResidentOnboardingChecklist from '../ResidentOnboarding/ResidentOnboardingChecklist';
import { OnboardingDashboardRow } from '../OnboardingDashboard';
import { PropertyHeader } from '../PropertyProfile';

import {
  defaultResidentOnboardingChecklist,
  defaultResidentList
} from './residentOnboardingData';

const resImagelist = [
  withPrefix('/images/company/onboarding-animation/res-001.png'),
  withPrefix('/images/company/onboarding-animation/res-002.png'),
  withPrefix('/images/company/onboarding-animation/res-003.png'),
  withPrefix('/images/company/onboarding-animation/res-004.png'),
  withPrefix('/images/company/onboarding-animation/res-005.png'),
  withPrefix('/images/company/onboarding-animation/res-006.png'),
  withPrefix('/images/company/onboarding-animation/res-007.png')
];

const dashImagelist = [
  withPrefix('/images/company/onboarding-animation/dash-001.png'),
  withPrefix('/images/company/onboarding-animation/dash-002.png'),
  withPrefix('/images/company/onboarding-animation/dash-003.png'),
  withPrefix('/images/company/onboarding-animation/dash-004.png'),
  withPrefix('/images/company/onboarding-animation/dash-005.png'),
  withPrefix('/images/company/onboarding-animation/dash-006.png'),
  withPrefix('/images/company/onboarding-animation/dash-007.png')
];

function OnboardingDashboardFrame({
  siteConfig,
  residentList,
  showDocs,
  setShowScreenshotPopup
}) {
  return (
    <>
      <div
        className="frame"
        onClick={() => document.getElementById('enlargeOnboarding').click()}>
        <div className="resident-list-container property-profile-container">
          <div className="resident-list">
            <PropertyHeader
              property={{
                PropertyName: 'The Jackson',
                Address: '4101 Viridian Village Dr',
                City: 'Arlington',
                State: 'TX',
                Zip: '76040'
              }}
              isCompleted
              activeRoute={'/property-onboarding'}
              siteConfig={siteConfig}
            />
            <OnboardingDashboardRow
              resident={residentList[0]}
              header
              walkthroughOnly={false}
            />
            {residentList.map((resident, residentIndex) => (
              <>
                <OnboardingDashboardRow
                  resident={resident}
                  showResidentOnboarding={null}
                  walkthroughOnly={false}
                  profileKey={'test'}
                  docExportOnly={showDocs}
                  // docExportOnly={
                  //   residentIndex === 0 &&
                  //   residentOnboardingChecklist[0]
                  //     .ResidentOnboardingStatusID === 3
                  // }
                />
              </>
            ))}
          </div>
        </div>
      </div>{' '}
      <Button
        className="enlarge"
        onClick={() =>
          setShowScreenshotPopup({
            url: withPrefix(
              '/images/company/screens/Onboarding Dashboard Enlarged.png'
            ),
            label: "Property's Onboarding Dashboard"
          })
        }
        id="enlargeOnboarding">
        <ZoomInIcon />
        Enlarge Image
      </Button>
    </>
  );
}

function DocsContent({ siteConfig, setShowScreenshotPopup }) {
  const docsSectionRef = useRef();
  const residentOnboardingChecklistCopy = useRef();
  const residentListCopy = useRef();
  const docsSectionIsVisible = useOnScreen(docsSectionRef);
  const docsSectionChecklistTimeout = useRef();
  const docsSectionOnboardingTimeout = useRef();
  const [docsAnimationInProgress, setDocsAnimationInProgress] = useState(null);
  const [docsAnimationStarted, setDocsAnimationStarted] = useState(null);
  const [activeSlide, setActiveSlide] = useState('onboarding');
  const [docsActiveRes, setDocsActiveRes] = useState(0);
  const docsActiveResCopy = useRef();
  const [docsActiveDash, setDocsActiveDash] = useState(0);
  const docsActiveDashCopy = useRef();

  const [residentOnboardingChecklist, setResidentOnboardingChecklist] =
    useState(getInitialChecklist());

  const [residentList, setResidentList] = useState(getInitialResidentList());

  residentOnboardingChecklistCopy.current = residentOnboardingChecklist;
  residentListCopy.current = residentList;
  docsActiveResCopy.current = docsActiveRes;
  docsActiveDashCopy.current = docsActiveDash;

  useEffect(() => {
    // cleanup function to cancel the timeout if it hasn't finished.
    return () => {
      if (docsSectionChecklistTimeout.current) {
        clearInterval(docsSectionChecklistTimeout.current);
      }

      if (docsSectionOnboardingTimeout.current) {
        clearTimeout(docsSectionOnboardingTimeout.current);
      }
    };
  }, []);

  useEffect(() => {
    if (docsSectionIsVisible) {
      if (!docsSectionChecklistTimeout.current) {
        playDocsAnimation();
      }
    }
  }, [docsSectionIsVisible]);

  function playDocsAnimation() {
    setDocsAnimationStarted(false);
    docsSectionOnboardingTimeout.current = setTimeout(() => {
      setDocsAnimationStarted(true);
      docsSectionChecklistTimeout.current = setTimeout(docsSectionNext, 1000);
    }, 1000);
  }

  function getInitialChecklist() {
    return defaultResidentOnboardingChecklist.map((item) => {
      let _residentOnboardingStatus = {
        ResidentOnboardingStatusID: 1,
        ResidentOnboardingStatusName: 'Action Required'
      };

      return {
        ...item,
        ..._residentOnboardingStatus
      };
    });
  }

  function getInitialResidentList() {
    return defaultResidentList.map((resident, residentIndex) => {
      if (residentIndex === 0) {
        let _resident = { ...resident };
        if (_resident.data) {
          Object.keys(_resident.data).map((grouping) => {
            let _residentOnboardingStatus = {
              ResidentOnboardingStatusID: 1,
              ResidentOnboardingStatusName: 'Action Required'
            };

            let _data = {
              ..._resident.data[grouping],
              ..._residentOnboardingStatus
            };
            _resident.data[grouping] = _data;
          });
        }
        return { ..._resident };
      }
      return { ...resident };
    });
  }

  function docsSectionNext() {
    // let _itemFound = null;
    // setResidentOnboardingChecklist(
    //   residentOnboardingChecklistCopy.current.map((item) => {
    //     let _item = { ...item };
    //     if (_item.ResidentOnboardingStatusID !== 3 && !_itemFound) {
    //       _itemFound = _item;
    //       _item.ResidentOnboardingStatusID = 3;
    //       _item.ResidentOnboardingStatusName = 'Completed';
    //     }
    //     return _item;
    //   })
    // );

    // if (!_itemFound) {
    //   setDocsAnimationInProgress(false);
    // } else {
    //   //docsSectionChecklistTimeout.current = setTimeout(docsSectionNext, 2000);
    //   docsSectionOnboardingTimeout.current = setTimeout(
    //     () => docsSectionOnboarding(_itemFound),
    //     500
    //   );
    //   setDocsAnimationInProgress(true);
    // }

    setDocsAnimationInProgress(true);

    if (docsActiveResCopy.current < resImagelist.length - 1) {
      setDocsActiveRes(docsActiveResCopy.current + 1);
      docsSectionChecklistTimeout.current = setTimeout(docsSectionNext, 2000);
      docsSectionOnboardingTimeout.current = setTimeout(() => {
        setDocsActiveDash(docsActiveDashCopy.current + 1);
      }, 500);
    } else {
      setDocsActiveRes(0);
      setDocsActiveDash(0);
      docsSectionChecklistTimeout.current = setTimeout(docsSectionNext, 2000);
    }
  }

  function docsSectionOnboarding(item) {
    setResidentList(
      residentListCopy.current.map((resident, residentIndex) => {
        if (residentIndex === 0) {
          let _resident = { ...resident };
          let _data = { ...resident.data };
          if (_resident.data && _resident.data[item.OnboardingColumn]) {
            _data[item.OnboardingColumn].ResidentOnboardingStatusID = 3;
            _data[item.OnboardingColumn].ResidentOnboardingStatusName =
              'Completed';
          }

          return { ..._resident, data: { ..._data } };
        }

        return { ...resident };
      })
    );
  }

  return (
    <div className="section docs" ref={docsSectionRef}>
      <div className="buttons">
        <Button
          className={
            'onboarding' + (activeSlide === 'onboarding' ? ' active' : '')
          }
          onClick={() => setActiveSlide('onboarding')}>
          <span className="icon">
            <GradingIcon />
          </span>
          Resident Onboarding
        </Button>
        <Button
          className={'document' + (activeSlide === 'document' ? ' active' : '')}
          //onClick={() => setActiveSlide('document')}
          onClick={() =>
            scrollToElementInPage(document.getElementById('docViewer'), -100)
          }>
          <span className="icon">
            <TextSnippetIcon />
          </span>{' '}
          Document Management
        </Button>
      </div>

      <div className={'section-content ' + activeSlide}>
        {/* <div className="side-buttons">
          {activeSlide === 'document' ? (
            <IconButton
              className="left"
              onClick={() => setActiveSlide('onboarding')}>
              <ArrowBackIosNewIcon />
            </IconButton>
          ) : null}
          {activeSlide !== 'document' ? (
            <IconButton
              className="right"
              onClick={() => setActiveSlide('document')}>
              <ArrowForwardIosIcon />
            </IconButton>
          ) : null}
        </div> */}

        {activeSlide === 'onboarding' ? (
          <div className="docs-content">
            <h3>Resident Onboarding</h3>
            <p>
              As new residents complete required tasks, the Property’s
              Onboarding Dashboard automatically updates to show the current
              status of each item in real-time. This creates a self-maintaining
              visual representation of the current status of all upcoming
              move-ins, making it much easier for properties to monitor and
              track pre-arrival compliance.
            </p>
            <div className="checklist">
              <h4>As resident completes tasks</h4>
              <div className="screenshot resident-onboarding">
                <div className="caption">
                  Resident's {siteConfig.brand} Checklist
                </div>

                {/* <img
                      src={withPrefix(
                        '/images/company/screenshot-checklist.jpg'
                      )}
                    /> */}

                <div className="checklist-container">
                  {/* <ResidentOnboardingChecklist
                    title="Resident Submittals"
                    checklist={residentOnboardingChecklist}
                  /> */}

                  {resImagelist.map((img, imgIndex) => (
                    <img
                      src={img}
                      className={imgIndex === docsActiveRes ? 'active' : ''}
                    />
                  ))}
                </div>

                <img
                  className={'arrow' + (docsActiveRes === 0 ? ' hidden' : '')}
                  src={withPrefix('/images/company/dot-arrow.svg')}
                />

                {docsAnimationInProgress ? (
                  <Button
                    className={'replay-button visible'}
                    onClick={() => {
                      // setResidentOnboardingChecklist(getInitialChecklist());
                      // setResidentList(getInitialResidentList());
                      if (docsSectionChecklistTimeout.current) {
                        clearTimeout(docsSectionChecklistTimeout.current);
                      }

                      if (docsSectionOnboardingTimeout) {
                        clearTimeout(docsSectionOnboardingTimeout.current);
                      }

                      setDocsAnimationInProgress(false);
                    }}>
                    Stop Animation
                  </Button>
                ) : (
                  <Button
                    className={'replay-button visible'}
                    onClick={() => {
                      // setResidentOnboardingChecklist(getInitialChecklist());
                      // setResidentList(getInitialResidentList());
                      docsSectionNext();
                      setDocsAnimationInProgress(true);
                    }}>
                    Play Animation
                  </Button>
                )}
              </div>
            </div>

            <div className="onboarding-dashboard">
              <h4>The Property's Onboarding Dashboard automatically updates</h4>

              {/* <OnboardingDashboardFrame
                siteConfig={siteConfig}
                residentList={residentList}
                showDocs={false}
                setShowScreenshotPopup={setShowScreenshotPopup}
              /> */}

              <div
                className="secondary-screenshot"
                onClick={() =>
                  document.getElementById('enlargeOnboarding').click()
                }>
                <img
                  src={withPrefix(
                    '/images/mi-landing/tablet-screenshot_90.svg'
                  )}
                />

                <div className="viewport">
                  {dashImagelist.map((img, imgIndex) => (
                    <img
                      src={img}
                      className={imgIndex === docsActiveDash ? 'active' : ''}
                    />
                  ))}
                </div>
              </div>

              <Button
                className="enlarge"
                onClick={() =>
                  setShowScreenshotPopup({
                    url: withPrefix(
                      '/images/company/screens/Onboarding Dashboard Enlarged.png'
                    ),
                    label: "Property's Onboarding Dashboard"
                  })
                }
                id="enlargeOnboarding">
                <ZoomInIcon />
                Enlarge Image
              </Button>
            </div>
          </div>
        ) : null}

        {/* <Button
          color="secondary"
          variat="contained"
          onClick={() => docsSectionNext()}>
          Play
        </Button> */}

        {/* {activeSlide === 'document' ? ( */}
        <DocViewer
          visible={true}
          setShowScreenshotPopup={setShowScreenshotPopup}
        />
        {/* ) : null} */}
      </div>
    </div>
  );
}

export default DocsContent;
