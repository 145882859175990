import React, { useEffect, useRef, useState } from 'react';
import { navigate } from 'gatsby-link';
import { useQueryParam } from 'gatsby-query-params';
import { connect, Provider } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withPrefix } from 'gatsby-link';
import {
  Parallax,
  ParallaxBanner,
  ParallaxProvider
} from 'react-scroll-parallax';
import { LinearProgress, Button } from '@material-ui/core';

import { getCompany } from '../../api/companyDataApi';
import { round } from '../../api/offerCalc';
import { scrollToElementInPage } from '../../api/dataApi';

import * as webSocketActions from '../../redux/actions/webSocketActions';

import RevenueCalc from './RevenueCalc';
import DashboardScreen from './DashboardScreen';
import DocsContent from './DocsContent';
import OtherTools from './OtherTools';
import Team from './Team';

import ScreenshotImagePopup from '../ScreenshotImagePopup';
import LoginButton from '../LandingMI/LoginButton';

import '../ResidentOnboarding/index.css';
import './index.css';

export function formatNumber(value, precision) {
  let formatted = parseFloat(
    parseFloat(round(value, precision)).toFixed(2)
  ).toLocaleString();

  const valueArr = formatted.split('.');

  if (!precision) {
    formatted = valueArr[0];
  } else if (valueArr[1] && String(valueArr[1]).length < precision) {
    for (let i = String(valueArr[1]).length; i < precision; i++) {
      formatted += '0';
    }
  } else if (!valueArr[1] && precision) {
    formatted += '.';
    for (let i = 0; i < precision; i++) {
      formatted += '0';
    }
  }
  return formatted;
}

export function doCalcs(_calcs) {
  _calcs.CountUnitTurn = _calcs.CountUnit * (_calcs.PercentTurn / 100);
  _calcs.CountTexasUnitTurn =
    _calcs.CountTexasDeregulatedUnit * (_calcs.PercentTurn / 100);

  _calcs.CountUnitEngagement = round(
    _calcs.CountUnitTurn * (_calcs.PercentEngagement / 100),
    0
  );
  _calcs.CountTexasUnitEngagement = round(
    _calcs.CountTexasUnitTurn * (_calcs.PercentEngagement / 100),
    0
  );

  _calcs.TotalCommissionInternet = _calcs.CountUnitEngagement
    ? round(_calcs.CountUnitEngagement * _calcs.AverageCommissionInternet, 2)
    : 0;
  _calcs.TotalCommissionElectric = _calcs.CountTexasUnitEngagement
    ? round(
        _calcs.CountTexasUnitEngagement * _calcs.AverageCommissionElectric,
        2
      )
    : 0;

  _calcs.TotalCommission =
    (_calcs.TotalCommissionInternet ? _calcs.TotalCommissionInternet : 0) +
    (_calcs.TotalCommissionElectric ? _calcs.TotalCommissionElectric : 0);

  _calcs.RevenueShareTotal = round(
    _calcs.TotalCommission * _calcs.PercentRevenueShare,
    2
  );

  _calcs.TotalEngagementPet = round(
    _calcs.CountUnitEngagement * _calcs.PercentPetRegistration,
    0
  );

  _calcs.RevenueSharePet = _calcs.CountUnitEngagement
    ? round(_calcs.TotalEngagementPet * _calcs.SuggestedFeePet, 2)
    : 0;

  _calcs.TotalEngagementVehicle = round(
    _calcs.CountUnitEngagement * _calcs.PercentVehicleRegistration,
    0
  );

  _calcs.RevenueShareVehicle = _calcs.CountUnitEngagement
    ? round(_calcs.TotalEngagementVehicle * _calcs.SuggestedFeeVehicle, 2)
    : 0;

  _calcs.RevenueShareTotalAdditional =
    (_calcs.RevenueSharePet ? _calcs.RevenueSharePet : 0) +
    (_calcs.RevenueShareVehicle ? _calcs.RevenueShareVehicle : 0);

  return _calcs;
}

function StaticContentInner({ siteConfig, company }) {
  const [showScreenshotPopup, setShowScreenshotPopup] = useState(null);
  const [showTellMeMore, setShowTellMeMore] = useState(null);
  return (
    <>
      <h2 id="residentDashboardAnchor" className="section segway">
        <strong>
          Improve the Move <em>with</em> {siteConfig.brand}
        </strong>
        Your residents and onsite teams will love what we do
        {company ? ' as well' : ''}!
      </h2>

      <div className="section dashboard">
        <div className="section-content">
          <DashboardScreen
            company={company}
            setShowScreenshotPopup={setShowScreenshotPopup}
          />

          <div className="dashboard-content">
            <h1>
              The <strong>Resident Dashboard</strong>
            </h1>
            <p>
              Our user friendly MovingIN dashboard simplifies the moving in
              process for new leases by breaking all their moving related tasks
              into small, bite-sized actions that can be easily tackled one item
              at a time. This helps them efficiently manage their entire move,
              from start to finish, in a convenient and organized manner that
              gets them ready to enjoy their amazing new home, stress-free, on
              day one.
            </p>
            <p>
              The dashboard was designed to be fully customizable and to work
              with your current process to enhance the move-in experience,
              improve new resident satisfaction, and save time by reducing
              onsite staff involvement.
            </p>

            <DashboardScreen
              company={company}
              setShowScreenshotPopup={setShowScreenshotPopup}
              mobile
            />

            <ul>
              <li>
                <img src={withPrefix('/images/misc/check-orange.svg')} />
                Items that you 'Require' before moving in are clearly marked
              </li>
              <li>
                <img src={withPrefix('/images/misc/check-orange.svg')} />
                No markup - our rates are the same as the resident would see if
                they were to go to the provider's site directly
              </li>
              <li>
                <img src={withPrefix('/images/misc/check-orange.svg')} />
                Properties can be set up in a matter of minutes
              </li>
              <li>
                <img src={withPrefix('/images/misc/check-orange.svg')} />
                Property specific customization throughout all sections
              </li>
              <li>
                <img src={withPrefix('/images/misc/check-orange.svg')} />
                Ability to spotlight your preferred partnerships
              </li>
              <li>
                <img src={withPrefix('/images/misc/check-orange.svg')} />
                No disruption to existing residents
              </li>
            </ul>

            <Button
              className="learn-more"
              variant="contained"
              color="primary"
              href="/resident-dashboard-overview"
              target="_blank">
              Tell me more...
            </Button>

            {/* <Button
              className="learn-more"
              variant="contained"
              color="primary"
              onClick={(event) => {
                setShowTellMeMore(true);
                event.preventDefault();
                event.stopPropagation();
              }}
              href="https://movingin.com"
              target="_blank">
              Expand section to see more...
            </Button>

            {showTellMeMore ? (
              <GeneralPopup
                title={'The Resident Dashboard'}
                className={'platform-tell-me-more-popup'}
                fullScreen
                message={
                  <>


                    <LandingMIMobile />
                  </>
                }
                hideActions
                closeFunc={() => setShowTellMeMore(false)}
                closeLabel={'Close'}
              />
            ) : null} */}
          </div>
        </div>
      </div>
      {/* <div className="section onboarding">
    <div className="section-content">
      <h1>
        The <strong>{siteConfig.brand}</strong> Dashboard for Properties
      </h1>
      <div className="onboarding-content">
        <p>
          <strong>
            Significantly reduce onsite staff time and effort across your
            entire portfolio by partnering with {siteConfig.brand} to
            automate the post-lease resident onboarding checklist.
          </strong>
        </p>
        <OnboardingScreen tablet />
        <p>
          {siteConfig.brand} will monitor each new resident’s submittals,
          and if necessary, politely remind them of any unfinished items in
          a timely manner so you don’t have to. As a result of this resident
          friendly approach, better than 90% of your residents will be fully
          compliant before their move-in date.
        </p>
        <p>
          We provide each property with the {siteConfig.brand} Dashboard
          below as a convenient visualization of the current status of each
          new residents ‘required’ items in real time. A red ‘Waiting on
          Resident’ button means the resident has not submitted the required
          information yet, a blue ‘Ready to Review’ button means they have,
          and we’re just waiting on the property to approve what the
          resident submitted.
        </p>
        <p>
          <a
            className="link"
            target="_blank"
            href={company.CountTexasDeregulatedUnit ? '/demo2' : '/demo1'}>
            Click here to see just how quick and easy the approval process
            is.
          </a>
        </p>
      </div>

      <OnboardingScreen />
    </div>
  </div> */}

      <div id="onboardingDashboardAnchor" className="section segway docs">
        <strong>The Property Dashboard </strong> Automate the Resident
        Onboarding and Document Management Process
      </div>

      <DocsContent
        siteConfig={siteConfig}
        setShowScreenshotPopup={setShowScreenshotPopup}
      />

      <OtherTools
        siteConfig={siteConfig}
        setShowScreenshotPopup={setShowScreenshotPopup}
      />

      <Team siteConfig={siteConfig} />

      {showScreenshotPopup ? (
        <ScreenshotImagePopup
          {...showScreenshotPopup}
          closeFunc={() => setShowScreenshotPopup(null)}
        />
      ) : null}
    </>
  );
}

function StaticContent({
  siteConfig,
  webSocket,
  actions,
  company,
  includeIntro,
  includeLogin
}) {
  function scrollToSection(section) {
    const element = document.getElementById('section-' + section);
    if (element) {
      scrollToElementInPage(element, -100, false);
    }
  }

  return includeIntro ? (
    <div className={'company-page initted'}>
      <div className="section platform-intro">
        <LoginButton />
        <div className="section-content">
          <div className="hero-content">
            <h1>
              Introducing the{' '}
              <img src={withPrefix(siteConfig.logo)} className="logo" />{' '}
              Resident Onboarding Platform
            </h1>

            <p>
              {siteConfig.brand} is an online ‘new resident’ onboarding platform
              that will assist your soon-to-be new residents with their upcoming
              move by helping them effectively manage the entire process,
              step-by-step, in the most efficient and least stressful way
              possible. The {siteConfig.brand} platform will benefit you and
              your onsite team as well by automating incoming resident tracking,
              pre-move in compliance status, and document retrieval and
              management.
            </p>

            <ul>
              <li className="resident">
                <Button
                  onClick={() => {
                    scrollToElementInPage(
                      document.getElementById('residentDashboardAnchor'),
                      -100
                    );
                  }}>
                  <div className="image">
                    <img
                      src={withPrefix('/images/roadmap/online-survey.png')}
                    />
                  </div>
                  Resident Dashboard
                </Button>
              </li>
              <li className="onboarding">
                <Button
                  onClick={() => {
                    scrollToElementInPage(
                      document.getElementById('onboardingDashboardAnchor'),
                      -100
                    );
                  }}>
                  <div className="image">
                    <img src={withPrefix('/images/roadmap/monitor.png')} />
                  </div>
                  Resident Onboarding
                </Button>
              </li>
              <li className="doc">
                <Button
                  onClick={() => {
                    scrollToElementInPage(
                      document.getElementById('docViewer'),
                      -100
                    );
                  }}>
                  <div className="image">
                    <img src={withPrefix('/images/roadmap/cloud.png')} />
                  </div>
                  Document Management
                </Button>
              </li>

              <li className="team">
                <Button
                  onClick={() => {
                    scrollToElementInPage(
                      document.getElementById('anchorTeam'),
                      -100
                    );
                  }}>
                  <div className="image">
                    <img src={withPrefix('/images/roadmap/fist.png')} />
                  </div>
                  Meet Your {siteConfig.brand} Team
                </Button>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <StaticContentInner
        siteConfig={siteConfig}
        company={company}
        includeLogin={includeLogin}
      />
    </div>
  ) : (
    <StaticContentInner
      siteConfig={siteConfig}
      company={company}
      includeLogin={includeLogin}
    />
  );
}

function mapStateToProps(state) {
  return {
    webSocket: state.webSocket,
    serviceAddress: state.serviceAddress,
    siteConfig: state.commonData.siteConfig,
    authUser: state.authUser
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      pageLoading: bindActionCreators(webSocketActions.pageLoading, dispatch)
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(StaticContent);
