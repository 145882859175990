import React, { useState } from 'react';
import { withPrefix } from 'gatsby-link';

import { Button, IconButton } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ZoomInIcon from '@mui/icons-material/ZoomIn';

import CheckBoxIcon from '@mui/icons-material/CheckBox';

function Screenshot({ img, onClick }) {
  return (
    <div
      className="secondary-screenshot"
      onClick={() => {
        if (onClick) {
          onClick();
        }
      }}>
      <img src={withPrefix('/images/mi-landing/tablet-screenshot.svg')} />

      <div className="viewport">
        <img src={img} />
      </div>
    </div>
  );
}

function OtherTools({ siteConfig, setShowScreenshotPopup }) {
  const [activeSlide, setActiveSlide] = useState('pet');

  function enlargeVehicleScreens(selectedUrl) {
    setShowScreenshotPopup({
      urlList: [
        withPrefix('/images/company/screens/Vehicle 1.jpg'),
        withPrefix('/images/company/screens/Vehicle 2.jpg'),
        withPrefix('/images/company/screens/Vehicle 3.1.jpg')
      ],
      label: 'Vehicle Registry',
      selectedUrl: selectedUrl
    });
  }

  function enlargePetScreens(selectedUrl) {
    setShowScreenshotPopup({
      urlList: [
        withPrefix('/images/company/screens/Pet Screen 1.jpg'),
        withPrefix('/images/company/screens/Pet Screen 2.jpg'),
        withPrefix('/images/company/screens/Pet Screen 3.1.jpg')
      ],
      label: 'Pet Profiles',
      selectedUrl: selectedUrl
    });
  }

  return (
    <>
      <h2 className="section segway other-tools">
        Sometimes it’s the little extras that help the most!
      </h2>
      <div className="section other-tools">
        <div className="section-content">
          <div className="buttons">
            <Button
              className={'pet' + (activeSlide === 'pet' ? ' active' : '')}
              onClick={() => setActiveSlide('pet')}>
              <span className="icon">
                <FontAwesomeIcon icon={['fa', 'dog']} />
              </span>{' '}
              Pet Profiles
            </Button>

            <Button
              className={
                'vehicle' + (activeSlide === 'vehicle' ? ' active' : '')
              }
              onClick={() => setActiveSlide('vehicle')}>
              <span className="icon">
                <FontAwesomeIcon icon={['fa', 'car-side']} />
              </span>{' '}
              Vehicle Registry
            </Button>
          </div>
        </div>

        <div className={'slides ' + activeSlide}>
          <div className="side-buttons">
            {activeSlide === 'vehicle' ? (
              <IconButton
                className="left"
                onClick={() => setActiveSlide('pet')}>
                <ArrowBackIosNewIcon />
              </IconButton>
            ) : null}
            {activeSlide !== 'vehicle' ? (
              <IconButton
                className="right"
                onClick={() => setActiveSlide('vehicle')}>
                <ArrowForwardIosIcon />
              </IconButton>
            ) : null}
          </div>
          <div
            className={'slide pet' + (activeSlide === 'pet' ? ' active' : '')}>
            <h3>Pet Profiles</h3>
            <p>
              Everyone loves a puppy, but sometimes they get loose and end up at
              your leasing office. No worries, our easily filtered Pet Profile
              tool will help your onsite team quickly determine if it’s a
              registered pet and if so, who it belongs to.
            </p>

            <Screenshot
              onClick={() =>
                enlargePetScreens(
                  withPrefix('/images/company/screens/Pet Screen 1.jpg')
                )
              }
              img={withPrefix('/images/company/screens/Pet Screen 1.jpg')}
            />

            <div className="inner-content">
              <p>
                Simply select the puppy’s attributes in the filters on the left:
              </p>
              <ul>
                <li>
                  <CheckBoxIcon /> Dog
                </li>
                <li>
                  <CheckBoxIcon /> Tan
                </li>
                <li>
                  <CheckBoxIcon /> Small
                </li>
              </ul>
              <p>
                And you immediately see all the small tan dogs at your
                community. Once you spot ‘Sammy’, just click on his Pet Profile
                to get his owners contact information.
              </p>
              <Screenshot
                onClick={() =>
                  enlargePetScreens(
                    withPrefix('/images/company/screens/Pet Screen 2.jpg')
                  )
                }
                img={withPrefix('/images/company/screens/Pet Screen 2.jpg')}
              />
              <Screenshot
                onClick={() =>
                  enlargePetScreens(
                    withPrefix('/images/company/screens/Pet Screen 3.1.jpg')
                  )
                }
                img={withPrefix('/images/company/screens/Pet Screen 3.1.jpg')}
              />
            </div>

            <Button
              className="enlarge"
              onClick={() => enlargePetScreens()}
              id="enlargePet">
              <ZoomInIcon />
              Enlarge Image
            </Button>
          </div>
          <div
            className={
              'slide vehicle' + (activeSlide === 'vehicle' ? ' active' : '')
            }>
            <div className="inner-content">
              <h3>Vehicle Registry</h3>
              <p>
                Our Vehicle Registry tool works in the same way. Say for example
                your garage camera records a red SUV backing into another
                vehicle and then driving off without leaving a note. Just select
                ‘Red’ and ‘SUV’ in the filters on the left, look over the
                results, and quickly ID the car and owner.
                <br />
                <br />
                There is even an editable ‘Notes’ section where the property can
                add notes to track parking policy violations, such as ‘Parked in
                spot reserved for Prospective Residents in front of leasing
                office on 02/05/2023’ or ‘Received complaint that this vehicle
                was parked in another resident's reserved spot (#314) on
                02/10/2023’.
              </p>
              <Screenshot
                img={withPrefix('/images/company/screens/Vehicle 1.jpg')}
                onClick={() =>
                  enlargeVehicleScreens(
                    withPrefix('/images/company/screens/Vehicle 1.jpg')
                  )
                }
              />
              <Screenshot
                img={withPrefix('/images/company/screens/Vehicle 2.jpg')}
                onClick={() =>
                  enlargeVehicleScreens(
                    withPrefix('/images/company/screens/Vehicle 2.jpg')
                  )
                }
              />
            </div>
            <Screenshot
              img={withPrefix('/images/company/screens/Vehicle 3.1.jpg')}
              onClick={() =>
                enlargeVehicleScreens(
                  withPrefix('/images/company/screens/Vehicle 3.1.jpg')
                )
              }
            />

            <Button
              className="enlarge"
              onClick={() => enlargeVehicleScreens()}
              id="enlargeVehicle">
              <ZoomInIcon />
              Enlarge Image
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}

export default OtherTools;
